import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Dropdown, Row, Col, ButtonGroup, Button, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import * as yup from 'yup';
import io from 'socket.io-client';
import { yupResolver } from '@hookform/resolvers/yup';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { FormInput } from '../../components';
import Loader from '../../components/Loader';

import { User } from '../../interfaces/User';
import { Message } from '../../interfaces/Message';
import {
    getMessages,
    getWhatsappTemplates,
    sendFreeformMessage,
    sendWhatsappTemplate,
    sendFreeformMessageWithDate,
    sendWhatsappTemplateWithDate,
    fetchJotformSubmissionManually,
    resendMessage,
    markCheckboxHandled,
} from '../../utils/api/messages';
import './chatArea.scss';
import useInterval from '../../utils/useInterval';
import Dropzone from 'react-dropzone';
import { FileType } from '../../interfaces/FileType';
import { uploadFile } from '../../utils/api/files';
import { APICore, getUserFromCookie, SOCKET_URL } from '../../utils/api/apiCore';
import { ApiError } from '../../utils/api/ApiError';
import { updatePokeUser, getChatMessages, updateChat } from '../../utils/api/chat';
import MessageItem from './MessageItem';
import { Client } from '../../interfaces/Client';
import { followClient, getFollowers } from '../../utils/api/clients';
import { Follower } from '../../interfaces/Follower';
import { UserProfileContext } from '../../context/userProfileContext';
import { assignBot, assignTestBot, cancelBot, cancelFutureMessage, sendFutureMessageNow } from '../../utils/api/automated-messages';
import BotsDropdown from './BotsDropdown';
import FutureMessageItem from './FutureMessageItem';
import { FutureMessage } from '../../interfaces/FutureMessage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../utils/formatDate';
import FollowersDropDown from './FollowersDropDown';
import { chatContext } from '../../context/chatContext';
import TemplatesDropdown from './TemplatesDropdown';
import { useUnansweredClientsContext } from '../../context/UnansweredClientsContext';
import globalErrorHandler from '../../utils/globalErrorHandler';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { Chatbot } from '../../interfaces/Chatbot';
import { ca } from 'date-fns/locale';

interface WhatsappMessage {
    message: string;
}

interface OpeningTemplate {
    templateName: string;
    templateBody: string;
}

const Events = Object.freeze({
    incomingMessage: 'incomingMessage',
    connectToClientCommand: 'connectToClientCommand',
});

interface ChatAreaProps {
    client: Client;
    rep?: User;
    sendMessageEnabled: boolean;
    showJotformLinks: boolean;
    chatTitle: string;
    polling: boolean;
    chatId?: string;
    unreadCount?: number;
    enableSocket?: boolean;
}

// ChatArea
const ChatArea = (props: ChatAreaProps) => {
    const { t } = useTranslation('chat');
    const { client, rep, sendMessageEnabled, showJotformLinks, chatTitle, polling, chatId, unreadCount, enableSocket = false } = props;

    const navigate = useNavigate();
    const [showBotUpload, setShowBotUpload] = useState(false);
    const [scrollToBottom, setScrollToBottom] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendingMessage, setSendingMessage] = useState<boolean>(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [futureMessages, setFutureMessages] = useState<FutureMessage[]>([]);
    const [assignedBots, setAssignedBots] = useState<any[]>([]);
    const [jotformLink1, setJotformLink1] = useState();
    const [jotformLink2, setJotformLink2] = useState();
    const [jotformLink3, setJotformLink3] = useState();
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<string>();
    const [currentModuleTemplates, setCurrentModuleTemplates] = useState<string[]>([]);
    const [viewAllTemplates, setViewAllTemplates] = useState<boolean>(false);
    const [allTemplates, setAllTemplates] = useState<string[]>([]);
    const [openingTemplates, setOpeningTemplates] = useState<OpeningTemplate[]>([]);
    const [whatsappMessage, setWhatsappMessage] = useState<WhatsappMessage>({
        message: '',
    });
    const [isConnected, setIsConnected] = useState(false);
    const [isFutureMessage, setIsFutureMessage] = useState(false);
    const [followers, setFollowers] = useState<Follower[]>([]);
    const { profileContextData } = useContext(UserProfileContext);
    const [bots, setBots] = useState([]);
    const [canSendFreeMessages, setCanSendFreeMessages] = useState(false);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const chatRef = useRef<any>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { setShouldUpdateChatsList } = useContext(chatContext);
    const { fetchUnansweredClients } = useUnansweredClientsContext();
    const fiveMin = 5 * 60 * 1000;
    const [socket, setSocket] = useState<any>(null);
    const [chatbot, setChatbot] = useState<Chatbot | undefined>();
    const [totalMessages, setTotalMessages] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [searchResultIndex, setSearchResultIndex] = useState<number>(-1);
    const [searchResultIds, setSearchResultIds] = useState<string[]>([]);
    const [selectedOpeningTemplate, setSelectedOpeningTemplate] = useState<OpeningTemplate | null>(null);
    const [showSearch, setShowSearch] = useState(false);
    const currentUser = new APICore().getLoggedInUser().user;
    const myId = currentUser._id;

    const handleSocket = () => {
        if (socket) {
            socket.disconnect();
        }

        const tmpSocket = io(SOCKET_URL);
        tmpSocket.on('connect', () => {
            setIsConnected(true);
            console.log('connected to chatArea socket');
            if (client)
                tmpSocket.emit(Events.connectToClientCommand, {
                    clientId: client._id,
                    token: getUserFromCookie().token,
                });
        });

        tmpSocket.on('disconnect', () => {
            console.log('disconnected form chatArea socket');
            setIsConnected(false);
        });

        tmpSocket.on(Events.incomingMessage, async () => {
            console.log('Socket chatArea event', Events.incomingMessage);
            // debugger;
            await handleMessages(true);
        });
        setSocket(tmpSocket);
    };

    let isLoading: boolean = false;

    useInterval(
        () => {
            if (!isLoading) {
                isLoading = true;
                handleMessages(true)
                    .catch((err) => {
                        globalErrorHandler(err);
                    })
                    .catch((err) => {
                        if (err.status === 401) {
                            return navigate('/auth/logout');
                        }
                        globalErrorHandler(err);
                    })
                    .finally(() => {
                        isLoading = false;
                    });
            } else {
                console.log('skipping get messages');
            }
        },
        polling ? fiveMin : 1000000000,
    );

    /*
     *  Fetches the messages for selected user
     */
    useEffect(() => {
        if (page !== 0) handleMessages(false);
    }, [page]);

    useEffect(() => {
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    const handleMessages = async (isOnLoad?: boolean, userChanged?: boolean) => {
        const newMessages: Message[] = isOnLoad || userChanged ? [] : [...messages];
        let result;

        if (chatId) {
            try {
                result = await getChatMessages(chatId, page, search);
                if (unreadCount) {
                    updateChat(chatId, { unreadCount: 0 }).catch((err) => {
                        globalErrorHandler(err);
                    });
                }
            } catch (error) {
                globalErrorHandler(error);
            }
        } else {
            result = await getMessages({
                clientId: client?._id,
                repId: rep?._id,
                search,
                page,
            });
        }

        result.messages.forEach((message: Message) => {
            const existing = newMessages.find((m) => m._id === message._id);

            if (existing) {
                existing.status = message.status;
            } else {
                newMessages.push(message);
            }
        });

        newMessages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        setMessages(newMessages);

        if (search) {
            setSearchResultIds(result.searchResultIds);
            setSearchResultIndex(0);
        }
        // setSearchResultIds(['66eff8baa83854d78d3d4d92', '66ece63a53c667602db8387f']);
        // setSearchResultIndex(0);

        setChatbot(result.chatbot || undefined);

        if (page === 0) {
            setTotalMessages(result.total);

            setScrollToBottom(true);

            setCanSendFreeMessages(result.canSendFreeMessages);
            // if (newMessages[0].client._id === client._id)
            setFutureMessages(result.futureMessages || []);
            setAssignedBots(result.assignedBots || []);

            setJotformLink1(result.jotformLink1);
            setJotformLink2(result.jotformLink2);
            setJotformLink3(result.jotformLink3);
        }
    };

    const handleIconClick = () => {
        if (isPickerOpen) {
            // If the date picker is open, close it
            setIsPickerOpen(false);
            if (selectedDate) {
                setIsFutureMessage(true);
            }
            return;
        }

        if (isFutureMessage) {
            setIsFutureMessage(false);
            return;
        }

        setIsPickerOpen(true);
    };

    const onLoad = async (userChanged?: boolean) => {
        setWhatsappMessage({ message: getClientChatText() });

        if (messages.length === 0 || userChanged) {
            setLoading(true);
        }

        if (userChanged) {
            handleSocket();
        }

        try {
            await handleMessages(true);
            setLoading(false);

            if (client && sendMessageEnabled) {
                await handleWhatsappTemplates(client);
            }

            // const result = await getAllBots();
            // setBots(result.bots);

            await handleFollowers();
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const handleFollowers = async () => {
        try {
            const { followers } = await getFollowers(client._id);
            // sort followers - first myId, then active, then followers, then the rest
            // find myId, set full name to 'You'
            if (followers.length) {
                const sortedFollowers = followers.sort((a: Follower, b: Follower) => {
                    if (a.user._id === myId) return -1;
                    if (b.user._id === myId) return 1;
                    if (a.isActiveRep && !b.isActiveRep) return -1;
                    if (b.isActiveRep && !a.isActiveRep) return 1;
                    if (a.isWatcher && !b.isWatcher) return -1;
                    if (b.isWatcher && !a.isWatcher) return 1;
                    if (a.isInvitee && !b.isInvitee) return -1;
                    if (b.isInvitee && !a.isInvitee) return 1;
                    return 0;
                });

                sortedFollowers[0].user.fullName = 'You';

                setFollowers(sortedFollowers);
            }
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const handleWhatsappTemplates = async (client: Client) => {
        const { messageTypes, openingTemplates, modulesMessageTypes } = await getWhatsappTemplates(client);

        setOpeningTemplates(
            openingTemplates.map((template: OpeningTemplate) => ({
                templateName: template.templateName,
                templateBody: template.templateBody.replace('{{1}}', ''),
            })),
        );
        setAllTemplates(messageTypes);
        // debugger;
        const { moduleName } = profileContextData?.selectedModule?.data || client.currentModule || {};
        if (moduleName) setCurrentModuleTemplates(modulesMessageTypes[moduleName] || []);
    };

    useEffect(() => {
        onLoad(true);
        setSearch('');
        setSearchResultIndex(-1);
        setSearchResultIds([]);

        return () => {
            if (socket) {
                socket.disconnect();
                socket.off('connect');
                socket.off('disconnect');
                socket.off(Events.incomingMessage);
            }
        };
    }, [chatId]);

    useEffect(() => {
        if (chatRef && chatRef.current && scrollToBottom) {
            chatRef.current.getScrollElement().scrollTop = chatRef.current.getScrollElement().scrollHeight;
            setScrollToBottom(false);
        }
    }, [messages, futureMessages, whatsappMessage.message]);

    const viewAllTemplatesClicked = (e: any) => {
        e.stopPropagation();
        setViewAllTemplates(!viewAllTemplates);
    };

    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            // newMessage: yup.string().required('Please enter your messsage'),
        }),
    );

    /*
     * form methods
     */
    const methods = useForm({ resolver: schemaResolver });
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        reset,
    } = methods;

    const chatNotActive = () => {
        alert('That chat becomes active for 24 hours after the user sends us a message');
    };

    /**
     * sends the chat message
     */
    const sendMessage = async () => {
        if (!whatsappMessage.message) return;
        if (sendingMessage || !client) return;

        if (isPreviewMode) {
            await sendTemplate();
            return;
        }

        if (!canSendFreeMessages && whatsappMessage.message.includes('\n')) {
            window.alert('You can only send one line messages or templates until the client replies');
            return;
        }

        try {
            setSendingMessage(true);
            reset();
            let message;
            if (selectedDate && isFutureMessage) {
                ({ message } = await sendFreeformMessageWithDate(client, whatsappMessage.message, selectedTemplate, selectedDate));
                setSelectedDate(null);
                setIsFutureMessage(false);
            } else {
                ({ message } = await sendFreeformMessage(client, whatsappMessage.message, selectedTemplate, selectedOpeningTemplate?.templateName));
            }
            fetchUnansweredClients();
            setMessages([...messages, message]);
            setScrollToBottom(true);
            setSelectedTemplate(undefined);
            setSendingMessage(false);
            setClientChatText('');
            setWhatsappMessage({ message: '' });
            setSelectedOpeningTemplate(null);
        } catch (err) {
            globalErrorHandler(err);
        } finally {
            setSendingMessage(false);

            setIsPreviewMode(false);
            if (setShouldUpdateChatsList && typeof setShouldUpdateChatsList === 'function') setShouldUpdateChatsList(true);
        }
    };

    const isChatInputEnabled = () => {
        return sendingMessage === false;
    };

    const isSendEnabled = () => isChatInputEnabled() || selectedTemplate;

    const getTemplateText = (messageType: string) => {
        if (messageType === 'zoomStartedWhatsapp') return 'Zoom has started';
        else if (messageType === 'zoomEndedWhatsapp') return 'Zoom has ended';
        else if (messageType === 'allDataReceivedWhatsapp') return 'All the documents received';
        else if (messageType === 'powerOfAttorneyWhatsapp') return 'Sign power of attorney docs';
        else if (messageType === 'uploadLinkWhatsapp') return 'Secure file upload link';
        else if (messageType === 'savingsFoundWhatsapp') return 'Savings Found';
        else if (messageType === 'zoom10MinutesWhatsappReminder') return '10 minutes to Zoom';
        else if (messageType === 'referralWhatsapp') return 'Refer a friend to Better';

        return messageType;
    };

    const whatsappTemplatesSelected = async (messageType: string) => {
        if (!client) return;

        setIsPreviewMode(true);
        setSelectedTemplate(messageType);
        setSendingMessage(true);
        try {
            const result = await sendWhatsappTemplate(client, messageType, true);

            setWhatsappMessage({ message: result.message });
            reset();
            // setSelectedTemplate('');
            setSendingMessage(false);
        } catch (err: any) {
            setSendingMessage(false);
            setIsPreviewMode(false);
            // globalErrorHandler(err);
            if (err.status === 500 && messageType === 'clientDashboardPensionSignLink') {
                alert('Documents for signature have to be generated first');
            } else {
                alert('Failed to receive template');
            }
        }
    };

    const sendTemplate = async () => {
        if (!client || !selectedTemplate) return;

        setSendingMessage(true);
        try {
            let result;
            if (selectedDate && isFutureMessage) {
                result = await sendWhatsappTemplateWithDate(client, selectedTemplate, false, selectedDate);
                setSelectedDate(null);
                setIsFutureMessage(false);
            } else {
                result = await sendWhatsappTemplate(client, selectedTemplate, false);
            }
            setWhatsappMessage({ message: '' });
            setIsPreviewMode(false);
            reset();
            await handleMessages(false);
        } catch (err: any) {
            globalErrorHandler(err);
        } finally {
            setSendingMessage(false);
        }
    };

    const setClientChatText = (text: string) => {
        localStorage.setItem(`chatText_${client._id}`, text);
    };

    const getClientChatText = (): string => {
        return localStorage.getItem(`chatText_${client._id}`) || '';
    };

    const handleChange = (event: any) => {
        const message = event.target.value;

        if (!selectedOpeningTemplate || message.startsWith(selectedOpeningTemplate.templateBody)) {
            setWhatsappMessage({ message });
            setClientChatText(message);
        }
    };

    const inputClass = () => {
        const r =
            !whatsappMessage.message || (whatsappMessage.message.charCodeAt(0) >= 1488 && whatsappMessage.message.charCodeAt(0) <= 1514)
                ? 'rtl'
                : 'ltr';

        return r;
    };

    const onFileUpload = async (files: FileType[]) => {
        if (client) {
            setSendingMessage(true);
            try {
                const result = await uploadFile({
                    clientId: client._id,
                    file: files[0],
                    sendToUser: true,
                });
                await handleMessages(true);
            } catch (err) {
                globalErrorHandler(err);
            } finally {
                setSendingMessage(true);
            }
        }
    };

    const getButtonClass = () => {
        let str = isSendEnabled() ? 'btn btn-success chat-send' : 'btn btn-disabled chat-send';
        if (isPreviewMode) str += ' w-75';
        else str += ' w-100';

        return str;
    };

    const cancelTemplate = () => {
        setIsPreviewMode(false);
        setWhatsappMessage({ message: '' });
        reset();
    };

    const onUploadTestBot = async (file: FileType | undefined) => {
        if (!file) return;

        try {
            setLoading(true);
            const result = await assignTestBot(client, file);

            await handleMessages(true);
            setLoading(false);

            alert('Test bot uploaded successfully');
        } catch (err: any) {
            setLoading(false);

            if (err.status === 410) {
                alert('Please send a message to system in order to test json');
            } else if (err.status === 409) {
                alert(err.message);
            } else {
                globalErrorHandler(err);
            }
        }
    };

    const onBotSelected = async (botName: string) => {
        try {
            setLoading(true);
            const result = await assignBot(client, botName);
            await handleMessages(true);
            fetchUnansweredClients();
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            globalErrorHandler(err);
        }
    };

    const onSendFutureMessageNow = async (message: FutureMessage) => {
        try {
            const result = await sendFutureMessageNow(message._id);
            await handleMessages(true);
            fetchUnansweredClients();
            alert('Message sent');
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const onResendMessage = async (messageId: string) => {
        try {
            const result = await resendMessage(messageId);
            await handleMessages(true);
            fetchUnansweredClients();
            alert('Message sent');
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const handleJotFetchClick = async (jotformType: string) => {
        try {
            const result = await fetchJotformSubmissionManually(client._id, jotformType);
            alert('Jotform fetched successfully');
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const onCancelFutureMessage = async (message: FutureMessage) => {
        try {
            const result = await cancelFutureMessage(message._id);
            await handleMessages(true);
            alert('Message cancelled');
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const onBotCancelled = async (assignedbot: string) => {
        try {
            if (!window.confirm(`Are you sure you want to cancel the bot ${assignedbot}?`)) {
                return;
            }

            await cancelBot(client, assignedbot);
            setAssignedBots(assignedBots.filter((b) => b !== assignedbot));
            await handleMessages(true);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const pokeUser = async (userId: string) => {
        try {
            const params = { chatId, userId, clientId: client._id };

            const result = await updatePokeUser(params);
            return result;
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const isFollowerPokable = (follower: Follower) => {
        //return (follower.user._id !== myId);
        return true;
    };

    const next = () => {
        setPage((page || 0) + 1);
    };

    const openingTemplateSelected = (template: OpeningTemplate | null) => {
        setSelectedOpeningTemplate(template);

        if (template) {
            setWhatsappMessage({ message: template.templateBody });
        }
        textAreaRef.current?.focus();
    };

    const getOpeningTemplates = () => {
        return (
            <div className="assign-opening-template">
                <Dropdown className="select-action" align={'end'}>
                    <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                        <span>
                            {selectedOpeningTemplate ? selectedOpeningTemplate.templateName : t('Select opening template')}
                            <i className="mdi mdi-chevron-down"></i>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-scroll">
                        <div className="dropdown-menu-inner">
                            <Dropdown.Item onClick={() => openingTemplateSelected(null)}>ללא תבנית</Dropdown.Item>
                            {openingTemplates.map((item: OpeningTemplate, index: number) => (
                                <Dropdown.Item key={index} onClick={() => openingTemplateSelected(item)}>
                                    {item.templateBody + '...'}
                                </Dropdown.Item>
                            ))}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const handleKeyDown = async (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchClicked();
        }
    };

    const searchClicked = () => {
        handleMessages(false);
        // setFilters({ ...filters, search });
    };

    const handleCheckChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!chatbot) {
            return;
        }

        try {
            await markCheckboxHandled(chatbot._id);
            setChatbot(undefined);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        if (searchResultIndex === undefined) return;

        if (searchResultIds.length > 0) {
            const messageId = searchResultIds[searchResultIndex];
            const messageIndex = messages.findIndex((message) => message._id === messageId);

            if (messageIndex !== -1) {
                const message = messages[messageIndex];
                const messageElement = document.getElementById(message._id);
                if (messageElement) {
                    messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }
    }, [searchResultIndex]);

    const prevResult = () => {
        if (searchResultIndex > 0) {
            setSearchResultIndex(searchResultIndex - 1);
        }
    };

    const nextResult = () => {
        if (searchResultIndex < searchResultIds.length - 1) {
            setSearchResultIndex(searchResultIndex + 1);
        }
    };

    const getMessagesHeight = () => {
        if (showSearch) {
            return 'calc(100vh - 450px)';
        }
        return 'calc(100vh - 382px)';
    };

    return (
        <div className="chat-area">
            <div className={'py-2 px-3 border-bottom chat-area-header'}>
                <Row className="justify-content-between py-1">
                    <div className="grid">
                        <div className="search" onClick={() => setShowSearch(!showSearch)}>
                            <i className="mdi mdi-magnify me-1"></i>
                        </div>
                        <div className="user-name">
                            <span className="mt-0 mb-0 font-15">
                                <Link
                                    to={{
                                        pathname: '/clients/profile',
                                        search: '?clientId=' + client._id,
                                    }}
                                >
                                    {' '}
                                    <b>{chatTitle}</b>
                                </Link>
                            </span>
                            {client.phoneNumberStatus && (
                                <span className={`badge ${client.phoneNumberStatus === 'valid' ? 'bg-success' : 'bg-warning'}`}>
                                    {client.phoneNumberStatus}
                                </span>
                            )}
                        </div>
                        <FollowersDropDown
                            followers={followers}
                            setFollowers={setFollowers}
                            client={client}
                            followClient={followClient}
                            pokeUser={pokeUser}
                            isFollowerPokable={isFollowerPokable}
                        />
                        {!!chatbot && <Form.Check label={t('Mark handled')} onChange={handleCheckChange} />}
                        <BotsDropdown onBotSelected={onBotSelected} onUploadTestBot={onUploadTestBot} />
                        <div className="assigned-bots">
                            {assignedBots.map((assignedBot: string) => (
                                <div key={assignedBot}>
                                    <span className="bot-name">{assignedBot}</span>
                                    <span className="cancel" onClick={() => onBotCancelled(assignedBot)}>
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Row>
                {showSearch && (
                    <Row>
                        <div className="search-bar form-inline search-wrapper">
                            <div className={`position-relative search-input ${!searchResultIds.length ? 'full-width' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('Search')}
                                    value={search}
                                    onChange={(e: any) => setSearch(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                                <span className="mdi mdi-magnify apply-search" onClick={searchClicked}></span>
                            </div>
                            <div className="search-result">
                                {searchResultIds.length > 0 ? (
                                    <>
                                        {searchResultIndex + 1} / {searchResultIds.length} matches
                                        <i className="float-end mdi mdi-chevron-right" onClick={prevResult}></i>
                                        <i className="float-end mdi mdi-chevron-left" onClick={nextResult}></i>
                                    </>
                                ) : (
                                    <span>No matches</span>
                                )}
                            </div>
                        </div>
                    </Row>
                )}
            </div>

            <div className="chat-area-body">
                {loading && <Loader />}
                {/* <SimpleBar
                        className={`whatsapp-window ${sendMessageEnabled ? 'includes-send-area' : ''}`}
                        style={{
                            height: `${chatId ? '' : (window.innerHeight - 165 - (sendMessageEnabled ? 174 : 0)) + "px"}`,
                            width: '100%'
                        }} ref={chatRef}> */}
                <InfiniteScroll
                    // ref={chatRef}
                    dataLength={messages.length + futureMessages.length}
                    next={next}
                    hasMore={messages.length < totalMessages}
                    loader={<h4 style={{ textAlign: 'center' }}>{'Loading...'}</h4>}
                    height={getMessagesHeight()}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    inverse={true}
                    endMessage={
                        <p style={{ textAlign: 'center', marginTop: '15px' }}>
                            <b>No more messages</b>
                        </p>
                    }
                >
                    <ul className="conversation-list">
                        {messages.map((message, index) => {
                            return <MessageItem key={index} message={message} onResendMessage={onResendMessage} id={message._id} />;
                        })}

                        {futureMessages.map((futureMessage, index) => {
                            return (
                                <FutureMessageItem
                                    id={futureMessage._id}
                                    key={index}
                                    message={futureMessage}
                                    onSendFutureMessageNow={onSendFutureMessageNow}
                                    onCancelFutureMessage={onCancelFutureMessage}
                                />
                            );
                        })}
                    </ul>
                </InfiniteScroll>

                {sendMessageEnabled ? (
                    <Row className="send-msg-box">
                        <Col className="form-wrapper">
                            <div className="col mb-2 mb-sm-0 no-margin-right">
                                {getOpeningTemplates()}
                                <div>
                                    <textarea
                                        ref={textAreaRef}
                                        onChange={handleChange}
                                        name="message"
                                        value={whatsappMessage.message}
                                        id="message"
                                        className={inputClass() + ' form-control'}
                                        placeholder={isChatInputEnabled() ? t('Enter your text') : t('Loading template text...')}
                                        disabled={!isChatInputEnabled() || isPreviewMode}
                                    />
                                </div>
                                {/* <FormInput
                                    // ref={textAreaRef}
                                    type="textarea"
                                    onChange={handleChange}
                                    name="message"
                                    value={whatsappMessage.message}
                                    className={inputClass()}
                                    placeholder={isChatInputEnabled() ? 'Enter your text' : 'Loading template text...'}
                                    register={register}
                                    key="message"
                                    errors={errors}
                                    disabled={!isChatInputEnabled() || isPreviewMode}
                                    control={control}
                                /> */}

                                {isChatInputEnabled() ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {!isPickerOpen && (
                                            <>
                                                {/* Cancel Template Button (if in preview mode) */}
                                                {isPreviewMode && (
                                                    <button
                                                        type="submit"
                                                        className={'btn btn-danger chat-send w-25'}
                                                        style={{ marginRight: '10px', marginTop: '10px' }} // Adjusted style
                                                        onClick={cancelTemplate}
                                                    >
                                                        {t('Cancel')}
                                                    </button>
                                                )}

                                                {/* Send Message Button */}
                                                <button
                                                    type="submit"
                                                    disabled={!isSendEnabled()}
                                                    onClick={sendMessage}
                                                    className={getButtonClass()}
                                                    style={{ margin: '10px 10px 10px 0' }} // Adjusted style
                                                >
                                                    {!sendingMessage ? (
                                                        <span>
                                                            <i className="fe-send"></i> {t('Send')}
                                                            {selectedDate && isFutureMessage
                                                                ? ` (${formatDate(selectedDate, false, false)})`
                                                                : ''}{' '}
                                                            {/* Conditionally showing the date */}
                                                        </span>
                                                    ) : (
                                                        <div className="status w-100">
                                                            <div className="spinner">
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </button>
                                            </>
                                        )}

                                        {/* Date Picker Trigger Icon */}
                                        <div
                                            style={{
                                                backgroundColor: isFutureMessage && selectedDate ? 'grey' : 'transparent', // changing background color based on date selection
                                                borderRadius: '50%', // if you want it to be circle-shaped
                                                height: '24px',
                                                width: '24px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleIconClick}
                                        >
                                            <ScheduleIcon
                                                style={{
                                                    color: isFutureMessage && selectedDate ? 'blue' : 'black', // changing colors based on date selection
                                                }}
                                            />
                                        </div>

                                        {isPickerOpen && (
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(date) => setSelectedDate(date)}
                                                excludeDateIntervals={[
                                                    {
                                                        start: new Date(0),
                                                        end: new Date(Date.now() - 24 * 60 * 60 * 1000),
                                                    },
                                                ]}
                                                // showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                inline
                                                timeInputLabel="Time:"
                                                showTimeInput
                                            />
                                        )}
                                    </div>
                                ) : null}

                                {currentModuleTemplates.length && !isPreviewMode ? (
                                    <TemplatesDropdown
                                        onTemplateSelected={whatsappTemplatesSelected}
                                        title={
                                            selectedTemplate
                                                ? getTemplateText(selectedTemplate)
                                                : t('Select template') +
                                                  (selectedDate && isFutureMessage ? ` (${formatDate(selectedDate, false, false)})` : '')
                                        }
                                    />
                                ) : null}
                                {!currentModuleTemplates.length && !isChatInputEnabled() ? (
                                    <div className="chat-inactive" onClick={chatNotActive}>
                                        {t('Chat is not active')}
                                    </div>
                                ) : null}
                            </div>

                            <div className="upload-attachment">
                                <Dropzone
                                    maxFiles={1}
                                    // onFileUpload={onFileUpload}
                                    onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            {!isPreviewMode ? (
                                                <div className="dz-message" {...getRootProps()}>
                                                    <i className="mdi mdi-attachment" />
                                                </div>
                                            ) : null}
                                            <div className="needsclick" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </Col>
                    </Row>
                ) : null}
                {showJotformLinks ? (
                    <Row>
                        <Row className="zoom-interview-form">
                            <Col className="mb-2 font-13">
                                <Link to={`${jotformLink1}`} target="_blank">
                                    <span>
                                        <i className="mdi mdi-form-select"></i>
                                        {t('Jotform 1')}
                                    </span>
                                </Link>
                                <br />
                                <br />
                                <Button variant="outline-success" size="sm" onClick={(e) => handleJotFetchClick('jotFormOne')}>
                                    {t('Fetch Jot 1')}
                                </Button>
                            </Col>
                            {jotformLink2 ? (
                                <Col className="mb-2 font-13">
                                    <Link to={`${jotformLink2}`} target="_blank">
                                        <span>
                                            <i className="mdi mdi-form-select"></i>
                                            {t('Jotform 2')}
                                        </span>
                                    </Link>
                                    <br />
                                    <br />
                                    <Button variant="outline-success" size="sm" onClick={(e) => handleJotFetchClick('jotFormTwo')}>
                                        {t('Fetch Jot 2')}
                                    </Button>
                                </Col>
                            ) : (
                                <Col className="inactive mb-2 font-13">
                                    <i className="mdi mdi-form-select"></i>
                                    {t('Jotform 2')}
                                </Col>
                            )}

                            {jotformLink3 ? (
                                <Col className="mb-2 font-13">
                                    <Link to={`${jotformLink3}`} target="_blank">
                                        <span>
                                            <i className="mdi mdi-form-select"></i>
                                            {t('Jotform 3')}
                                        </span>
                                    </Link>
                                    <br />
                                    <br />
                                    <Button variant="outline-success" size="sm" onClick={(e) => handleJotFetchClick('jotFormThree')}>
                                        {t('Fetch Jot 3')}
                                    </Button>
                                </Col>
                            ) : (
                                <Col className="inactive mb-2 font-13">
                                    <i className="mdi mdi-form-select"></i>
                                    {t('Jotform 3')}
                                </Col>
                            )}
                        </Row>
                    </Row>
                ) : null}
            </div>
        </div>
    );
};

export default ChatArea;
