import { Dropdown } from 'react-bootstrap';

interface ChatItemMenuProps {
    handleMenuSelect: (e: string | null) => void;
    markAsUnreadDisabled: boolean;
    showUnpoke: boolean;
}

const ChatItemMenu = ({ handleMenuSelect, markAsUnreadDisabled, showUnpoke }: ChatItemMenuProps) => {
    return (
        <div className="chat-item-menu">
            <Dropdown onClick={(e) => e.stopPropagation()} onSelect={handleMenuSelect} align={'end'}>
                <Dropdown.Toggle variant="transparent" id="chat-item-menu-dropdown">
                    <i className="mdi mdi-dots-vertical text-secondary fs-4 cursor-pointer"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="border">
                    <Dropdown.Item disabled={markAsUnreadDisabled} eventKey="1">
                        Mark as unread
                    </Dropdown.Item>
                    {showUnpoke && <Dropdown.Item eventKey="2">Unpoke</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default ChatItemMenu;
