import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Modules from '../../../utils/modules';
import { Investment } from '../../../interfaces/Investment';
import { formatDate } from '../../../utils/formatDate';
import { Client } from '../../../interfaces/Client';
import { useEnumsContext } from '../../../context/EnumsContext';
import Select from 'react-select';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { currentRound, client } = profileContextData as {
        currentRound: Investment;
        client: Client;
    };

    const investment: Investment = profileContextData.currentRound as Investment;

    const [riskToleranceOptions, setRiskToleranceOptions] = useState<string[]>([]);
    const enums = useEnumsContext();
    useEffect(() => {
        if (enums && enums.RiskTolerance) {
            const options = enums.RiskTolerance;
            setRiskToleranceOptions(Object.values(options));
        }
    }, [enums]);

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('investment')),
    );
    console.log(
        'users',
        users.filter((rep: User) => rep.roles.includes('agent')),
    );
    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('investment')));
    }, [users]);

    const lastChangeDateString = investment?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(investment.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div className="select-area">
                {client && investment ? (
                    <>
                        {investment.investmentPreferredTypes.length > 0 && (
                            <InputWrapper status={{ text: '' }} label={''} wrapperClass="input-field select-field">
                                <div className="badges">
                                    {' '}
                                    {investment.investmentPreferredTypes?.map((investmentPreferredType: string) => (
                                        <>
                                            <span className={`badge bg-success float-Start`}>{camelCaseToText(investmentPreferredType)}</span>{' '}
                                        </>
                                    ))}
                                </div>
                            </InputWrapper>
                        )}

                        {allowedStatusesOptions && allowedStatusesOptions.length > 0 ? (
                            <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                                <Select
                                    options={allowedStatusesOptions.map((option: any, index: number) => ({
                                        label: camelCaseToText(option),
                                        value: option,
                                    }))}
                                    onChange={(item: any) => onEditRound({ status: item.value }, selectedModuleName, currentRound._id)}
                                    value={{ label: camelCaseToText(currentRound.status), value: currentRound.status }}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '100%',
                                            // minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        ) : null}
                        {repAgents && repAgents.length > 0 ? (
                            <InputWrapper status={{ text: '' }} label={'Agent / Rep'} wrapperClass="input-field select-field">
                                <Select
                                    options={repAgents.map((option: any, index: number) => ({
                                        label: option.fullName,
                                        value: option._id,
                                    }))}
                                    onChange={(item: any) => onEditRound({ [repType]: item.value }, selectedModuleName, currentRound._id)}
                                    value={{
                                        label: repAgents.find((rep: any) => rep._id === currentRound.rep)?.fullName,
                                        value: currentRound.rep,
                                    }}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '100%',
                                            // minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        ) : (
                            <div className="pension-status">No {repType} found.</div>
                        )}
                        <InputWrapper status={{ text: '' }} label={'Risk Tolerance'} wrapperClass="input-field select-field">
                            <select
                                value={currentRound.riskTolerance || ''}
                                className="form-select"
                                name="rep"
                                onChange={(e: any) => {
                                    onEditRound({ riskTolerance: e.target.value }, selectedModuleName, currentRound._id);
                                }}
                            >
                                <option disabled value="">
                                    Select Risk Tolerance{' '}
                                </option>

                                {riskToleranceOptions.map((option: any, index: number) => (
                                    <option key={index} value={option}>
                                        {camelCaseToText(option)}
                                    </option>
                                ))}
                            </select>
                        </InputWrapper>
                        <div>
                            <b>Agent meeting date: </b>
                            <span>{formatDate((currentRound as Investment).agentMeetingTime)}</span>
                        </div>
                    </>
                ) : (
                    <div>Sorry, no data yet.</div>
                )}
            </div>
        </div>
    );
};

export default Details;
