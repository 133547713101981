import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import './clientProfile.scss';
import { UserProfileContext } from '../../context/userProfileContext';
import { useNavigate } from 'react-router-dom';
import EditClient from '../clients/EditClient';
import Tasks from './Tasks';
import Comments from '../../components/Comments';
import { useQuery } from '../../hooks';
import Loader from '../../components/Loader';
import { ApiError } from '../../utils/api/ApiError';
import OffCanvasWrapper from '../../components/offCanvas/OffCanvasWrapper';
import { activateNextModule, createOnbaording, editClient, editOnboarding, getClient, getClientsModules } from '../../utils/api/clients';
import { editPension, createPension } from '../../utils/api/pension';
import { createTaxReturn, editTaxReturn } from '../../utils/api/taxReturn';
import { Client } from '../../interfaces/Client';
import Onboarding from './onboarding/Onboarding';
import Pension from './pension/Pension';
import TaxReturn from './taxReturn/TaxReturn';
import Modules from '../../utils/modules';
import { getTaskCount } from '../../utils/api/tasks';
import { User } from '../../interfaces/User';
import { getUsers } from '../../utils/api/users';
import Mortgage from './mortgage/Mortgage';
import { createMortgage, editMortgage } from '../../utils/api/mortgage';
import { createInsurance, editInsurance } from '../../utils/api/insurance';
import { createLoan, editLoan } from '../../utils/api/loan';
import { createDeposit, editDeposit } from '../../utils/api/deposit';
import Loan from './loan/Loan';
import Deposit from './deposits/Deposit';
import Insurance from './insurance/InsurancePage';
import { createBankFees, editBankFees } from '../../utils/api/bankFees';
import BankFees from './bankFees/BankFees';
import Investment from './investments/Investment';
import { createInvestment, editInvestment } from '../../utils/api/investment';
import { set } from 'date-fns';
import useComments from '../../hooks/useComments';
import globalErrorHandler from '../../utils/globalErrorHandler';
import Drawers from '../../components/Drowers';
import { createPcInsurance, editPcInsurance } from '../../utils/api/pcInsurance';
import PCInsurance from './pcInsurance/PCInsurance';

const Profile = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const mounted = useRef(false);

    const [client, setClient] = useState<Client>();
    const [isLoading, setIsLoading] = useState(false);
    const [modules, setModules] = useState<any>({});
    const [openTasksCount, setOpenTasksCount] = useState<number>(0);
    const [showAddClient, setShowAddClient] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);

    const [profileContextData, setProfileContextData] = useState<any>();

    const [isRoundBeingCreated, setIsRoundBeingCreated] = useState(false);

    useEffect(() => {
        if (client && modules && Object.keys(modules).length > 0) {
            const contextData = { ...profileContextData };

            if (contextData.selectedModule) {
                const { moduleName } = contextData.selectedModule.data;
                for (const key in modules) {
                    if (modules[key].data.moduleName === moduleName) {
                        contextData.selectedModule = modules[key];
                    }
                }
            } else {
                const moduleName = query.get('module') || 'onboarding';
                for (const key in modules) {
                    if (modules[key].data.moduleName === moduleName) {
                        contextData.selectedModule = modules[key];
                        break;
                    }
                }
                if (!contextData.selectedModule) contextData.selectedModule = modules.onboardings;
            }

            if (!contextData.currentRound && contextData.selectedModule) contextData.currentRound = contextData.selectedModule.data.rounds[0];

            contextData.modules = modules;
            contextData.client = client;
            contextData.setClient = setClient;

            setProfileContextData(contextData);
        }
    }, [client, modules]);

    // useEffect(() => {
    //     if (profileContextData.selectedModule) {
    //         setProfileContextData( ( prev: any ) => ({
    //             ...prev,
    //             // isSelectedActive: profileContextData?.selectedModule?.serverName === client?.currentModule + "s",
    //             // allowedStatusesOptions: modules[client?.currentModule + "s"]?.allowedStatuses ?  Object.values(modules[client?.currentModule + "s"]?.allowedStatuses) : [],
    //         }))
    //     }
    // }, [profileContextData.selectedModule, client])

    const onEditRound = async (body: any, module: string, roundId: string, onSuccess?: () => void, onError?: () => void) => {
        const prevData = { ...profileContextData };
        const contextData = { ...profileContextData };
        const round = contextData.selectedModule.data.rounds.find((r: any) => r._id === roundId);

        for (const key in body) {
            // contextData.currentRound[key] = body[key];
            round[key] = body[key];
        }

        setProfileContextData(contextData);
        let response;

        try {
            if (module === Modules.onboarding) {
                response = await editOnboarding(roundId, body);
                await handleModules();
            } else if (module === Modules.pension) {
                response = await editPension(roundId, body);
            } else if (module === Modules.taxReturn) {
                response = await editTaxReturn(roundId, body);
            } else if (module === Modules.mortgage) {
                response = await editMortgage(roundId, body);
            } else if (module === Modules.deposit) {
                response = await editDeposit(roundId, body);
            } else if (module === Modules.loans) {
                response = await editLoan(roundId, body);
            } else if (module === Modules.insurance) {
                response = await editInsurance(roundId, body);
            } else if (module === Modules.pcInsurance) {
                response = await editPcInsurance(roundId, body);
            } else if (module === Modules.bankFees) {
                response = await editBankFees(roundId, body);
            } else if (module === Modules.investment) {
                response = await editInvestment(roundId, body);
            }

            const newCurrentRound = response[module];
            const newContextData = { ...profileContextData };
            for (const key in newCurrentRound) {
                newContextData.currentRound[key] = newCurrentRound[key];
            }
            setProfileContextData(newContextData);
            if (onSuccess) {
                onSuccess();
            }
        } catch (err: any) {
            if (onError) onError();
            setProfileContextData(prevData);
            if (err.errorCode === 'POWER_OF_ATTORNEY_OUTDATED') {
                alert('Power of attorney out of date');
            } else if (err.status === 402) {
                alert('Please set signing probability');
            } else if (err.status === 405) {
                alert("The status you are trying to set is not allowed, client didn't sign forms");
            } else if (err.status === 410) {
                alert('Please generate signing forms');
            } else if (err.status === 411) {
                alert('The following files are still missing: ' + err.extraData.join(', '));
            } else if (err.status === 412) {
                alert('Please generate the signing documents first');
            } else if (err.status === 413) {
                alert('Id number is either empty or invalid - make sure to set the right one');
            } else if (err.status === 414) {
                alert('Status cannot change before all Jotforms are submitted');
                window.location.reload();
            } else {
                globalErrorHandler(err);
            }
        }
    };

    const onCreateNewRound = async (clientId: string, module: string) => {
        setIsRoundBeingCreated(true);
        try {
            if (module === Modules.onboarding) await createOnbaording(clientId);
            else if (module === Modules.pension) await createPension(clientId);
            else if (module === Modules.taxReturn) await createTaxReturn(clientId);
            else if (module === Modules.mortgage) await createMortgage(clientId);
            else if (module === Modules.deposit) await createDeposit(clientId);
            else if (module === Modules.loans) await createLoan(clientId);
            else if (module === Modules.insurance) await createInsurance(clientId);
            else if (module === Modules.pcInsurance) await createPcInsurance(clientId);
            else if (module === Modules.bankFees) await createBankFees(clientId);
            else if (module === Modules.investment) await createInvestment(clientId);
            else return;

            await handleModules();
        } catch (err: any) {
            if (module === Modules.taxReturn) {
                if (err.status === 402) {
                    alert('Client is not eligible for tax return');
                    return;
                }
            }

            if (module === Modules.pension) {
                if (err.status === 402) {
                    alert(err.message || err.message);
                    return;
                }
            }

            globalErrorHandler(err);
        } finally {
            setIsRoundBeingCreated(false);
        }
    };

    const onCloseAddClientModal = () => setShowAddClient(false);

    const onEditClient = () => {
        setShowAddClient(true);
    };

    const getData = async () => {
        const clientId = query.get('clientId');
        if (!clientId) {
            navigate('/clients');
            return;
        }

        const result: PromiseSettledResult<any>[] = await Promise.allSettled([
            getClient(clientId),
            getClientsModules(clientId),
            getTaskCount({ clientId }),
            getUsers(),
        ]);

        // check if result[0] was fullfilled

        if (result[0].status === 'fulfilled') {
            setClient(result[0].value.client);
        } else {
            if (result[0].reason instanceof ApiError && result[0].reason.status === 401) return navigate('/auth/logout');

            globalErrorHandler(result[0].reason);
        }

        if (result[1].status === 'fulfilled') {
            setModules(arrangeModules(result[1].value.modules));
        }

        if (result[2].status === 'fulfilled') {
            setOpenTasksCount(result[2].value.count);
        }

        if (result[3].status === 'fulfilled') {
            setUsers(result[3].value.users);
        }

        setIsLoading(false);
    };

    const handleModules = async () => {
        try {
            const clientId = query.get('clientId');
            if (!clientId) {
                navigate('/clients');
                return;
            }

            const result = await getClientsModules(clientId);

            setModules(arrangeModules(result.modules));
        } catch (err: any) {
            globalErrorHandler(err);
            if (err.status === 401) return navigate('/auth/logout');
        } finally {
            setIsLoading(false);
        }
    };

    const arrangeModules = (modules: any) => {
        for (const key in modules) {
            modules[key].data = {
                moduleName: Object.values(Modules).find((module) => key.includes(module)),
                rounds: modules[key][key] || [],
                allowedStatuses: modules[key].allowedStatuses,
                permittedToEdit: modules[key].permittedToEdit,
                permittedToView: modules[key].permittedToView,
            };
            // modules[key].data.rounds.forEach((round: any, i: number) => round.round = i+1);
        }
        return modules;
    };

    useEffect(() => {
        mounted.current = true;
        if (mounted) {
            setIsLoading(true);
            getData();
        }

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (client && client.fullName) {
            document.title = client.fullName;
        }

        return () => {
            document.title = 'Better Admin';
        };
    }, [client]);

    const onEditClientForm = async (newClient: Client, oldClient?: Client) => {
        try {
            if (oldClient) {
                await editClient(oldClient._id, newClient);
                setClient(newClient);
            }
            onCloseAddClientModal();
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const getContent = () => {
        const moduleName = profileContextData?.selectedModule?.data?.moduleName;
        if (!moduleName) return;

        if (moduleName === Modules.onboarding) return <Onboarding />;
        else if (moduleName === Modules.pension) return <Pension />;
        else if (moduleName === Modules.taxReturn) return <TaxReturn />;
        else if (moduleName === Modules.mortgage) return <Mortgage />;
        else if (moduleName === Modules.loans) return <Loan />;
        else if (moduleName === Modules.deposit) return <Deposit />;
        else if (moduleName === Modules.insurance) return <Insurance />;
        else if (moduleName === Modules.pcInsurance) return <PCInsurance />;
        else if (moduleName === Modules.bankFees) return <BankFees />;
        else if (moduleName === Modules.investment) return <Investment />;
    };

    return (
        <UserProfileContext.Provider
            value={{
                profileContextData,
                users,
                setProfileContextData,
                onCreateNewRound,
                onEditClient,
                onEditRound,
                isRoundBeingCreated,
            }}
        >
            {/*<UserProfileContext.Provider value={{}}>*/}
            <div className="profile-wrapper">
                <Drawers client={client} selectedModule={profileContextData?.selectedModule?.data?.moduleName} openTasksCount={openTasksCount} />

                {isLoading && <Loader />}

                {getContent()}
            </div>
            {client && <EditClient show={showAddClient} onHide={onCloseAddClientModal} onAddClient={onEditClientForm} client={client} />}
        </UserProfileContext.Provider>
    );
};

export default Profile;
