import { Client } from '../../interfaces/Client';
import { APICore, API_URL, TAX_URL, DASHBOARD_URL, CLIENT_SEARCH_URL } from './apiCore';

const api = new APICore();

const getClients = (params: {
    page?: number;
    isVIP?: string;
    notInterested?: string;
    lastStatusChangeDate?: Date;
    lastMessageDate?: Date;
    overdue?: string;
    isStarred?: string;
    isActive?: string;
    repId?: string;
    onboarderId?: string;
    search?: string;
    isRelevantForTaxReturn?: string;
    status?: string;
    sourceType?: string;
    campaign?: string;
    sortBy?: string;
    module?: string;
    startDate?: Date | string;
    endDate?: Date | string;
}) => {
    const relativeUrl = '/clients';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const getClient = (id: string) => {
    const relativeUrl = '/clients/' + id;
    return api.get(`${API_URL}${relativeUrl}`);
};

const editClient = (id: string, client: any) => {
    console.trace('!');
    const relativeUrl = '/clients/' + id;
    return api.update(`${API_URL}${relativeUrl}`, client);
};

const deleteClient = (id: string, israeliId: string) => {
    const relativeUrl = `/clients/${id}?userIdNumber=${israeliId}`;
    return api.delete(`${API_URL}${relativeUrl}`);
};

const getClientsModules = (clientId: string) => {
    const relativeUrl = '/clients/' + clientId + '/modules/';
    return api.get(`${API_URL}${relativeUrl}`);
};

const activateNextModule = (clientId: string, module: string) => {
    const relativeUrl = '/clients/' + clientId + '/modules/next';
    return api.create(`${API_URL}${relativeUrl}`, { module });
};

const createClientSpouse = ({ clientId, spouse }: { clientId: string; spouse: Client }) => {
    const relativeUrl = '/clients/' + clientId + '/spouse';
    return api.create(`${API_URL}${relativeUrl}`, spouse);
};

const followClient = (action: string, clientId: string, userId?: string) => {
    const body = {
        userId,
        action,
    };
    const relativeUrl = '/clients/' + clientId + '/followers';
    return api.create(`${API_URL}${relativeUrl}`, body);
};

const getFollowers = (clientId: string) => {
    const relativeUrl = '/clients/' + clientId + '/followers';
    return api.get(`${API_URL}${relativeUrl}`);
};

const createOnbaording = (clientId: string) => {
    return api.create(`${API_URL}/onboarding`, { clientId });
};

const editOnboarding = (onboardingId: string, body: any) => {
    const relativeUrl = `/onboarding/${onboardingId}`;
    return api.update(`${API_URL}${relativeUrl}`, body);
};

const getOnboardingStatuses = async () => {
    const relativeUrl = '/search/enums';
    const result = await api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
    const { OnboardingStatuses } = result;
    return {
        statuses: Object.keys(OnboardingStatuses),
    };
};

const getAllCampaignNames = () => {
    return api.get(`${API_URL}/clients/campaign-names`);
};

const getClientsFile = (params: any) => {
    const relativeUrl = '/clients/download';
    return api.getFile(`${API_URL}${relativeUrl}`, params);
};

const createClient = (client: Client) => {
    const relativeUrl = '/clients';
    return api.create(`${API_URL}${relativeUrl}`, client);
};

const getMissingFiles = (params: {
    page?: number;
    repId?: string;
    clientId?: string;
    search?: string;
    status?: string;
    sortBy?: string;
    isEligible?: boolean;
    module?: string;
}) => {
    const relativeUrl = '/clients/files-submission';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const getMissingFilesFile = (params: { page?: number; repId?: string; search?: string; status?: string; sortBy?: string; module?: string }) => {
    const relativeUrl = '/clients/download-files-submission';
    return api.getFile(`${API_URL}${relativeUrl}`, params);
};

// const deleteUser = (id: string ) => {
//     const relativeUrl = '/clients/'+id;
//     return api.delete(`${API_URL}${relativeUrl}`);
// }

// const logout = (token: string) => {
//     const relativeUrl = '/users/logout';
//     return api.get(`${API_URL}${relativeUrl}`, undefined, token);
// }

//! Never been used:
const getClientsAutocomplete = (text: string) => {
    const relativeUrl = '/clients/autocomplete';
    return api.get(`${API_URL}${relativeUrl}`, { text });
};

const getClientFilesZip = (clientId: string, fileClassifications: string[]) => {
    const relativeUrl = '/clients/' + clientId + '/download-files';
    return api.getFileWithBody(`${API_URL}${relativeUrl}`, {
        fileClassifications,
    });
};

export {
    getClientsAutocomplete,
    getMissingFiles,
    getMissingFilesFile,
    getClients,
    getClient,
    getClientsModules,
    editClient,
    deleteClient,
    getClientsFile,
    createClient,
    activateNextModule,
    editOnboarding,
    getOnboardingStatuses,
    followClient,
    getFollowers,
    createClientSpouse,
    createOnbaording,
    getClientFilesZip,
    getAllCampaignNames,
};
