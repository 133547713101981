import { APICore, CLIENT_SEARCH_URL } from './apiCore';

const api = new APICore();

const getClientsBySearch = (params: any) => {
    // debugger;
    const relativeUrl = '/search/client';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`, params);
};

const getClientsAutocomplete = (params: { freeText: string }) => {
    const relativeUrl = '/search/client/autocomplete';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`, params);
};

const getEnums = () => {
    const relativeUrl = '/search/enums';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
};

const getClientsByIds = (params: { clientIds: string[] }) => {
    const relativeUrl = `/search/clients?${convertArrayToUrlQuery('clientIds', params.clientIds)}`;
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
};

const convertArrayToUrlQuery = (arrayName: string, array: string[]) => {
    let res = '';
    array.forEach((element, index) => {
        res += `${arrayName}[${index}]=${element}&`;
    });
    return res;
};

const getStatusesFrequency = (params: { module: string; showInactiveStatuses: boolean }) => {
    const relativeUrl = `/search/client/module-status/count`;
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`, {
        isActive: !params.showInactiveStatuses,
        moduleName: params.module,
    });
};

export { getClientsBySearch, getClientsAutocomplete, getEnums, getClientsByIds, getStatusesFrequency };
