import { APICore, API_URL, PENSION_ZOON_URL } from './apiCore';
import { NewPensionProduct } from '../../interfaces/NewPensionProduct';
import { InventoryProductsFetchParams, PensionProduct } from '../../interfaces/PensionProduct';
import { Pension } from '../../interfaces/Pension';
import { PensionProductCommissionsFetchParams } from '../../interfaces/PensionProductCommissions';

const api = new APICore();
// const API_URL = 'http://localhost:5000/api'

const getProducts = (params: { pensionId?: string; productId?: string }) => {
    const relativeUrl = '/products';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const getProduct = (productId: string) => {
    const relativeUrl = '/products/' + productId;
    return api.get(`${API_URL}${relativeUrl}`);
};

const removeProduct = (productId: string) => {
    const relativeUrl = '/products/' + productId;
    return api.delete(`${API_URL}${relativeUrl}`);
};

const getNewProductData = (isPensionRound: boolean = true) => {
    const relativeUrl = '/products/new-product-data';
    return api.get(`${API_URL}${relativeUrl}`, { isPensionRound });
};

const createProduct = (newProduct: NewPensionProduct) => {
    const relativeUrl = '/products';
    return api.create(`${API_URL}${relativeUrl}`, newProduct);
};

const getDeposits = (params: { pensionId: string }) => {
    const relativeUrl = '/products/deposits';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const simulateCommissions = (productId: string, params: { savingsCommission: number; depositCommission: number }[]) => {
    const relativeUrl = `/products/${productId}/simulate`;
    const newParams: any = params.reduce((acc, curr) => {
        acc += `simulations[]=${encodeURIComponent(JSON.stringify(curr))}`;
        acc += '&';
        return acc;
    }, '');
    return api.get(`${API_URL}${relativeUrl}`, newParams);
};

const getSimulatedCommissionsDocFile = (productId: string, params: any) => {
    const relativeUrl = `/products/${productId}/download-simulation-doc`;
    return api.getFile(`${API_URL}${relativeUrl}`, params);
};

const getActionableProducts = (params: { page: number | undefined }) => {
    const relativeUrl = '/products/actionable';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const getDiscountForm = (productId: string) => {
    const relativeUrl = `/products/${productId}/discount-doc`;
    return api.getFile(`${API_URL}${relativeUrl}`);
};

const getB2Form = (productId: string, preview?: boolean) => {
    const relativeUrl = `/products/${productId}/b2Form`;
    if (preview) return api.getFile(`${API_URL}${relativeUrl}`, { preview });
    else return api.get(`${API_URL}${relativeUrl}`);
};

const paymentHaltForm = (productId: string, preview?: boolean) => {
    const relativeUrl = `/products/${productId}/paymentHaltForm`;
    if (preview) return api.getFile(`${API_URL}${relativeUrl}`, { preview });
    else return api.get(`${API_URL}${relativeUrl}`);
};

const addComment = (productId: string, comment: string) => {
    const relativeUrl = `/products/${productId}/comment`;
    return api.create(`${API_URL}${relativeUrl}`, { comment });
};

const submitB2 = (productId: string) => {
    const relativeUrl = `/products/${productId}/submitB2Form`;
    return api.create(`${API_URL}${relativeUrl}`, {});
};

const getHanmaka = (productId: string) => {
    const relativeUrl = `/products/${productId}/hanmaka`;
    return api.getFile(`${API_URL}${relativeUrl}`);
};

const getFullHanmaka = (clientId: string, preview?: boolean) => {
    const relativeUrl = `/products/hanmaka`;
    if (preview) return api.getFile(`${API_URL}${relativeUrl}`, { clientId, preview });
    else return api.get(`${API_URL}${relativeUrl}`, { clientId });
};

const updateProducts = (clientId: string) => {
    const relativeUrl = `/pension-zoom/update/?clientId=${clientId}`;
    return api.create(`${PENSION_ZOON_URL}${relativeUrl}`, {});
};

const getProductsForPensionZoom = (clientId: string) => {
    const relativeUrl = `/pension-zoom/products?clientId=${clientId}`;
    return api.get(`${PENSION_ZOON_URL}${relativeUrl}`);
};

const getInventoryProducts = (params?: InventoryProductsFetchParams) => {
    const relativeUrl = '/inventory-products';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const getCommissionsData = (params?: PensionProductCommissionsFetchParams) => {
    const relativeUrl = '/pension/commissions';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const getInventoryProductsFile = (params?: InventoryProductsFetchParams) => {
    const relativeUrl = '/inventory-products/download';
    return api.getFile(`${API_URL}${relativeUrl}`, params);
};

export {
    removeProduct,
    createProduct,
    getNewProductData,
    getB2Form,
    paymentHaltForm,
    getHanmaka,
    getFullHanmaka,
    addComment,
    getDeposits,
    getDiscountForm,
    getProducts,
    getProduct,
    getActionableProducts,
    simulateCommissions,
    submitB2,
    updateProducts,
    getProductsForPensionZoom,
    getInventoryProducts,
    getCommissionsData,
    getInventoryProductsFile,
    getSimulatedCommissionsDocFile,
};
