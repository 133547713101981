import { Client } from './Client';
import { User } from './User';

export interface Pension {
    _id: string;
    signingProbability: string;
    client: string;
    rep: string;
    agentMeetingTime: Date;
    agent?: User | string;
    operator: string;
    status: string;
    temporarilyIrrelevantReason?: string;
    temporarilyIrrelevantEndDate?: Date;
    isActive: boolean;
    round: number;
    endTime: Date;
    files: string[];
    mislaka: {
        submissionDate: Date;
    };
    isCubesDocSentToEmployer?: boolean;
    lastStatusChangeDate: Date | string;
    irrelevantReason: PensionIrrelevantReasons | string;
    sendFormsAutomatically: boolean;
    isInvestmentRound: boolean;
    meetingType: PensionMeetingTypes;
    zoomUri?: string;
    mislakaSubmissionDate: Date;
}

export enum PensionMeetingTypes {
    phone = 'phone',
    zoom = 'zoom',
    faceToFace = 'faceToFace',
}

export enum PensionIrrelevantReasons {
    recentlyMadeChanges = 'recentlyMadeChanges',
    justWantedToHear = 'justWantedToHear',
    residesAbroad = 'residesAbroad',
    incorrectSystemRecommendation = 'incorrectSystemRecommendation',
    notSupposedToReceiveSavingsMessage = 'notSupposedToReceiveSavingMessage',
    unpaidVacation = 'unpaidVacation',
    maternityLeave = 'maternityLeave',
    approachingCompletion = 'approachingCompletion',
    other = 'other',
}
