import { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { chatContext } from '../../context/chatContext';
import { Chat, ChatStatuses } from '../../interfaces/Chat';
import { formatDateByTimePassed } from '../../utils/formatDate';
import ChatItemMenu from './ChatItemMenu';
import { useChatAndTaskCountContext } from '../../context/ChatAndTaskCountContext';
import vipAvatar from '../../assets/images/users/vip.png';
interface ChatItemProps {
    chat: Chat;
    onChatSelected: (chat: Chat) => void;
    starChat: (chat: Chat) => void;
    pinChat: (chat: Chat) => void;
    setUnreadCountChat: (chat: Chat) => void;
    onUnpoke: (chat: Chat) => void;
}

const ChatItem = ({ chat, starChat, pinChat, onChatSelected, setUnreadCountChat, onUnpoke }: ChatItemProps) => {
    const { chatData } = useContext(chatContext);

    const isFollower = () => {
        if (chat.follower && chatData.systemUser.userId === chat.follower) {
            return true;
        }
    };

    const getJoinToolTip = () => {
        if (chat.status === ChatStatuses.inactive) {
            // Chat itself can be marked as not active for current handling rep,
            // if the pensionStatus moved to new group, so the chat moved to a new rep.
            return 'This chat is no longer active for you, due to pensionStatus change.';
        }

        if (!chatData.systemUser.isActive) {
            // Occurs when current system user (rep) is marked as not active by its manager.
            return 'You are not active.';
        }

        if (isFollower()) {
            return 'You joined this chat.';
        } else if (chat.follower) {
            return 'Other agent joined this chat.';
        }
        return 'Manager joined this chat.';
    };

    const getStatusItemClass = () => {
        let classes = '';

        if (chatData.selectedChat?._id === chat._id) {
            classes += ' item-selected';
        } else if (chat.pokedBy) {
            classes += ' item-poked';
        }

        return classes;
    };

    const starChatClicked = async (e: any) => {
        e.stopPropagation();
        starChat(chat);
    };

    const pinChatClicked = async (e: any) => {
        e.stopPropagation();
        pinChat(chat);
    };

    const handleMenuSelect = (e: any) => {
        switch (e) {
            case '1':
                setUnreadCountChat(chat);
                break;
            case '2':
                onUnpoke(chat);
                break;
            default:
                break;
        }
    };

    const getAction = () => {
        const joinedModeNotification =
            (chat.follower || chat.status === ChatStatuses.transferredToManager || chat.status === ChatStatuses.inactive) &&
            !chatData.systemUser.isManager;

        if (!joinedModeNotification) return;

        return (
            <div className="action" onClick={(e) => e.stopPropagation()}>
                {joinedModeNotification ? (
                    <div className="joined-mode-notification">
                        <OverlayTrigger placement="right" overlay={<Tooltip id="">{getJoinToolTip()}</Tooltip>}>
                            <div className="join-icon-wrp">
                                {chat.status === ChatStatuses.inactive ? (
                                    <i className={`mdi  mdi-comment-remove-outline`}></i>
                                ) : (
                                    <i className={`mdi ${isFollower() ? 'mdi-account-multiple' : 'mdi-account-plus'}`}></i>
                                )}
                                {chat.status === ChatStatuses.transferredToManager && <i className={`mdi mdi-flash`}></i>}
                            </div>
                        </OverlayTrigger>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <li className={`chat-item ${getStatusItemClass()} `} onClick={() => onChatSelected(chat)}>
            <div className="avatar cursor-pointer">
                <img src={chat.client.isVIP ? vipAvatar : '/static/media/user-avatar.8d49e377c7c342895ec2.png'} alt="" width={40} />
            </div>

            <div className="display cursor-pointer">
                <div className="names">
                    <div className="client-name">{chat.client.fullName}</div>
                    {chatData.systemUser.isManager && chat.rep ? <div className="rep-name">{chat.rep.fullName}</div> : ''}
                </div>
                <p className="preview">{chat.lastMessage?.body}</p>
            </div>

            {<i className={'mdi mdi-star star' + (chat.isStarred ? ' starred' : '')} onClick={starChatClicked}></i>}
            <div className={`update ${chat?.unreadCount > 0 ? 'new-msg' : ''}`}>
                <div className="count"> {chat?.unreadCount > 0 ? chat.unreadCount : ''}</div>

                <div className="time">{formatDateByTimePassed(chat.lastMessageTime)}</div>
            </div>

            <div className="pin icon">{<i className={'mdi mdi-pin pin' + (chat.isPinned ? ' pinned' : '')} onClick={pinChatClicked}></i>}</div>

            {chatData.systemUser.isActive ? (
                getAction()
            ) : (
                <OverlayTrigger placement="right" overlay={<Tooltip id="">{getJoinToolTip()}</Tooltip>}>
                    <i className={`mdi mdi-bell-off`}></i>
                </OverlayTrigger>
            )}

            <ChatItemMenu handleMenuSelect={handleMenuSelect} markAsUnreadDisabled={chat.unreadCount > 0} showUnpoke={!!chat.pokedBy} />
        </li>
    );
};

export default ChatItem;
