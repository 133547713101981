import { APICore, API_URL, INSURANCE_URL } from './apiCore';

const api = new APICore();

const createPcInsurance = (clientId: string) => {
    return api.create(`${INSURANCE_URL}/pc-insurance`, { clientId });
};

const editPcInsurance = (pcInsuranceId: string, body: any) => {
    const relativeUrl = `/pc-insurance/${pcInsuranceId}`;
    return api.update(`${INSURANCE_URL}${relativeUrl}`, body);
};

const getPcInsuranceStatuses = () => {
    return api.get(`${INSURANCE_URL}/pc-insurance/statuses`);
};

export { createPcInsurance, editPcInsurance, getPcInsuranceStatuses };
