import { useEffect, useState } from 'react';
import Table from '../../../components/Table';
import { InsuranceAppendix } from '../../../interfaces/InsuranceAppendix';
import { PolicyCommentColumn, PolicyInsuredListColumn, appendixColumns } from './insuranceProductColumns';
import CreateOrEditAppendixModal from './insuranceCreationAndEdit/AppendixCreationAndEditingModal';
import { editExistingAppendix } from './insuranceCreationAndEdit/appendixAndPolicyCreationFunctions';
import { InsurancePolicy } from '../../../interfaces/InsurancePolicy';
import {
    PolicyHolderColumn,
    PolicyPlanTypeColumn,
    PolicyPremiumColumn,
    PolicyPremiumDiscountPercentageColumn,
    PolicyPremiumTermColumn,
    PolicyStartDateColumn,
    PolicyEndDateColumn,
    PolicyCancellationAppendixCColumn,
    PolicyCancellationAppendixAColumn,
    PolicyNoCancellationRequired,
} from './insuranceProductColumns';
import { useTranslation } from 'react-i18next';
import BorderWithText from '../../../components/BorderWithText';
import globalErrorHandler from '../../../utils/globalErrorHandler';

interface PolicyRowTableExpansionProps {
    appendices: InsuranceAppendix[];
    setAppendices: (appendices: InsuranceAppendix[]) => void;
    policy: InsurancePolicy;
}
const PolicyRowTableExpansion = ({ policy, appendices, setAppendices }: PolicyRowTableExpansionProps) => {
    const { t } = useTranslation('insurance');
    const [appendixToEdit, setAppendixToEdit] = useState<InsuranceAppendix | null>(null);
    const [showEditAppendixModal, setShowEditAppendixModal] = useState(false);

    useEffect(() => {
        if (appendixToEdit) {
            setShowEditAppendixModal(true);
        }
    }, [appendixToEdit]);

    useEffect(() => {
        if (!showEditAppendixModal) {
            setAppendixToEdit(null);
        }
    }, [showEditAppendixModal]);

    const handleEditAppendix = async (modifiedStateOfAppendix: InsuranceAppendix) => {
        try {
            const editedInsuranceAppendix = await editExistingAppendix(modifiedStateOfAppendix);
            setAppendices(
                appendices.map((insuranceAppendix) =>
                    insuranceAppendix._id === editedInsuranceAppendix._id ? editedInsuranceAppendix : insuranceAppendix,
                ),
            );
            return editedInsuranceAppendix;
        } catch (error) {
            globalErrorHandler(error);
        } finally {
            setShowEditAppendixModal(false);
        }
    };

    const rowStyle = (row: any) => {
        const appendix = row.original;

        const styles: any = {};

        if (appendix.isNew) {
            styles.backgroundColor = 'rgb(198, 236, 227, 0.65)';
        }

        return styles;
    };

    return (
        <div className="p-2">
            {/* {policyItReplacesNumber && <div className="row mb-4 text-center"> <span className="fw-bolder">Policy It Replaces: <span className="fw-normal ">{policyItReplacesNumber}</span></span>  </div>} */}
            <div className="row mb-4 mt-1">
                {[
                    { label: 'Policy Holder', Component: PolicyHolderColumn },
                    { label: 'Insured List', Component: PolicyInsuredListColumn },
                    {
                        label: 'Premium Discount Percentage',
                        Component: PolicyPremiumDiscountPercentageColumn,
                    },
                    { label: 'Premium Term', Component: PolicyPremiumTermColumn },
                    { label: 'Start Date', Component: PolicyStartDateColumn },
                    { label: 'End Date', Component: PolicyEndDateColumn },
                    { label: 'Comments', Component: PolicyCommentColumn },

                    ...(policy.isNewPolicy === false
                        ? [
                              {
                                  label: 'Cancellation Appendix C',
                                  Component: PolicyCancellationAppendixCColumn,
                              },
                              {
                                  label: 'Cancellation Appendix A',
                                  Component: PolicyCancellationAppendixAColumn,
                              },
                              {
                                  label: 'No Cancellation Required',
                                  Component: PolicyNoCancellationRequired,
                              },
                          ]
                        : []),
                ].map(({ label, Component }, index) => (
                    <div key={index} className="col-md-6 col-lg-4 col-xl-2 mb-3 me-1">
                        <div className="d-flex flex-column gap-1">
                            <span className="fw-bold">{t(label)}:</span>
                            {Component ? <Component row={{ original: policy }} /> : <></>}
                        </div>
                    </div>
                ))}
            </div>

            <BorderWithText label={t('Appendices')}>
                <Table columns={appendixColumns} data={appendices} rowNumbering={true} rowStyle={rowStyle} />
            </BorderWithText>

            {appendixToEdit && showEditAppendixModal && (
                <CreateOrEditAppendixModal
                    show={showEditAppendixModal}
                    onHide={() => setShowEditAppendixModal(false)}
                    onSubmit={handleEditAppendix}
                    currentAppendixState={appendixToEdit}
                />
            )}
        </div>
    );
};

export default PolicyRowTableExpansion;
