import React, { useEffect, useState } from 'react';
import { Modal, Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { Employer, EmployerTypeOptions, PricingPlans } from '../../interfaces/Employer';
import { FormInput, VerticalForm } from '../../components';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { useTranslation } from 'react-i18next';
import EmployerAutocomplete from '../../components/EmployerAutocomplete';
import { createEmployer, editEmployer } from '../../utils/api/employers';
import globalErrorHandler from '../../utils/globalErrorHandler';
import { debug } from 'console';

interface AddEmployerProps {
    show: boolean;
    onHide: () => void;
    onAddEmployer?: (employer: Employer) => void;
    onEditEmployer?: (employer: Employer) => void;
    employer?: Employer;
    showShortName?: boolean;
    newEmployerName?: string;
}

const newEmployer = {
    _id: '',
    type: 'company',
    name: '',
    shortName: '',
    companyId: '',
    b2b: false,
    address: '',
    contactPersons: [
        {
            name: '',
            phoneNumber: '',
            email: '',
        },
    ],
    pensionOperators: [],
};
const AddEmployer = (params: AddEmployerProps) => {
    const { t } = useTranslation('employers');

    const [employer, setEmployer] = useState<Employer>();

    useEffect(() => {
        if (params.employer) {
            setEmployer(params.employer);
        }
    }, [params.employer]);

    useEffect(() => {
        if (params.newEmployerName) {
            const companyId = isNaN(Number(params.newEmployerName)) ? '' : params.newEmployerName;
            const name = isNaN(Number(params.newEmployerName)) ? params.newEmployerName : '';
            const employer: any = {
                name,
                companyId,
                contactPerson: {},
                type: 'company',
            };
            setEmployer(employer);
        }
    }, [params.newEmployerName]);

    const onSubmit = async () => {
        if (employer) {
            if (!employer.name) {
                alert('Please enter employer name');
                return;
            }

            if (employer.companyId && !/^\d+$/.test(employer.companyId)) {
                alert('Company ID must be a number');
                return;
            }

            try {
                if (params.employer) {
                    const result = await editEmployer(params.employer._id, employer);
                    if (params.onEditEmployer) params.onEditEmployer(result.employer);
                } else {
                    const result = await createEmployer(employer);
                    if (params.onAddEmployer) params.onAddEmployer(result.employer);
                }
            } catch (err: any) {
                debugger;
                globalErrorHandler(err);
            }
        }
    };

    const onContactPersonChanged = (e: any, index: number) => {
        if (!employer) return;

        const { name, value } = e.target;
        const tmp: Employer = { ...employer };
        tmp.contactPersons[index] = { ...tmp.contactPersons[index], [name]: value };

        setEmployer(tmp);
    };

    const onChange = (e: any) => {
        const { name, value } = e.target;
        const tmp: any = { ...employer, [name]: value };
        if (name === 'pensionOperators') {
            tmp.pensionOperators = [value];
        }
        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            if (!tmp[parent]) {
                tmp[parent] = {};
            }
            tmp[parent][child] = value;
        }
        if (!tmp.contactPerson) {
            tmp.contactPerson = {};
        }
        setEmployer(tmp);
    };

    const getCompanyIdLabel = () => {
        if (employer?.type === 'selfEmployed') {
            return `Self Employed Number (${t('Self Employed Number')})`;
        }
        if (employer?.type === 'ngo') {
            return 'NGO Number';
        }
        return `Company ID (${t('Company ID')})`;
    };

    const getTitle = () => {
        if (params.employer) return 'Edit Employer';

        return 'Add New Employer';
    };

    const addContactPerson = () => {
        if (!employer) return;
        const tmp: Employer = { ...employer };
        tmp.contactPersons.unshift({ name: '', phoneNumber: '', email: '', remarks: '' });
        setEmployer(tmp);
    };

    return (
        <>
            <Modal show={params.show} onHide={params.onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={params.onHide} closeButton>
                    <Modal.Title data-test-id="employer-title" className="m-0">
                        {getTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <FormInput
                        label="Employer type"
                        type="select"
                        onChange={onChange}
                        value={employer?.type}
                        name="type"
                        placeholder="Status"
                        containerClass={'mb-3'}
                        options={EmployerTypeOptions.map((type: string) => ({
                            label: camelCaseToText(type) || '',
                            value: type,
                        }))}
                        // onInputChange={onInputChange}
                    />

                    <FormInput
                        // value={employer?.firstName}
                        label="Formal Business Name"
                        onChange={onChange}
                        type="text"
                        name="name"
                        value={employer?.name}
                        placeholder="Enter formal name"
                        containerClass={'mb-3'}
                    />
                    {params.showShortName && (
                        <FormInput
                            // value={employer?.firstName}
                            label="Short Name"
                            value={employer?.shortName}
                            onChange={onChange}
                            type="text"
                            name="shortName"
                            placeholder="Enter short name"
                            containerClass={'mb-3'}
                        />
                    )}
                    {employer && employer.type !== EmployerTypeOptions[2] && (
                        <FormInput
                            label={getCompanyIdLabel()}
                            onChange={onChange}
                            type="text"
                            value={employer?.companyId}
                            name="companyId"
                            placeholder="Enter company ID"
                            containerClass={'mb-3'}
                        />
                    )}

                    <FormInput
                        label="Address"
                        onChange={onChange}
                        type="text"
                        value={employer?.address}
                        name="address"
                        placeholder="Enter full address"
                        containerClass={'mb-3'}
                    />

                    <FormInput
                        label="Pension Operator"
                        onChange={onChange}
                        type="text"
                        value={employer?.pensionOperators}
                        name="pensionOperators"
                        placeholder="Enter pension agent"
                        containerClass={'mb-3'}
                    />

                    <FormInput
                        label="B2B"
                        onChange={onChange}
                        type="checkbox"
                        checked={employer?.b2b}
                        name="b2b"
                        placeholder="B2B status"
                        containerClass={'mb-3'}
                    />

                    <FormInput
                        label="Lecture Date"
                        onChange={onChange}
                        type="date"
                        value={employer?.lectureDate ? new Date(employer?.lectureDate).toISOString().split('T')[0] : ''}
                        name="lectureDate"
                        placeholder="Enter lecture date"
                        containerClass={'mb-3'}
                    />

                    <p>
                        <b>Contact Persons</b>{' '}
                        <Button className="small-button" onClick={addContactPerson}>
                            Add
                        </Button>
                    </p>
                    {employer?.contactPersons?.map((contactPerson: any, index: number) => (
                        <div key={index}>
                            <FormInput
                                label={(employer?.contactPersons.length > 1 ? `${index + 1}. ` : '') + 'Full Name'}
                                onChange={(e: any) => onContactPersonChanged(e, index)}
                                type="text"
                                value={contactPerson.name}
                                name="name"
                                placeholder="Enter full name"
                                containerClass={'mb-3'}
                            />
                            <FormInput
                                label="Phone Number"
                                onChange={(e: any) => onContactPersonChanged(e, index)}
                                type="text"
                                value={contactPerson.phoneNumber}
                                name="phoneNumber"
                                placeholder="Enter full name"
                                containerClass={'mb-3'}
                            />
                            <FormInput
                                label="Email"
                                onChange={(e: any) => onContactPersonChanged(e, index)}
                                type="text"
                                value={contactPerson.email}
                                name="email"
                                placeholder="Enter full name"
                                containerClass={'mb-3'}
                            />

                            <FormInput
                                label="Remarks"
                                onChange={(e: any) => onContactPersonChanged(e, index)}
                                type="textarea"
                                value={contactPerson.remarks}
                                name="remarks"
                                placeholder="Remarks regarding contact person"
                                containerClass={'mb-3'}
                            />
                        </div>
                    ))}

                    <div className="text-end">
                        <Button variant="success" type="submit" className="waves-effect waves-light me-1" onClick={onSubmit}>
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEmployer;
