import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { Pension } from '../../../interfaces/Pension';
import Modules from '../../../utils/modules';
import { TaxReturn } from '../../../interfaces/taxReturn';
import { Mortgage } from '../../../interfaces/Mortgage';
import { APICore } from '../../../utils/api/apiCore';
import { displayNumericInputFieldsValue } from '../../../utils/displayNumericValues';
import { DebounceInput } from 'react-debounce-input';
import { formatDate } from '../../../utils/formatDate';
import Select from 'react-select';

const Details = () => {
    const navigate = useNavigate();

    const loggedInUser = new APICore().getLoggedInUser()?.user;
    const canUserEdit = loggedInUser && (loggedInUser?.roles.includes('agent') || loggedInUser?.roles.includes('operator') || loggedInUser?.isAdmin);

    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [currentRound, setCurrentRound] = useState<Mortgage>(profileContextData.currentRound as Mortgage);

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('taxReturn')),
    );
    const [repOperators, setRepOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));

    console.log(
        'users',
        users.filter((rep: User) => rep.roles.includes('operator')),
    );
    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('mortgage')));
        setRepOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);

    const hasOperator = (object: any): object is Pension | TaxReturn | Mortgage => {
        return 'operator' in object;
    };

    const callToOnEditRound = (data: any) => {
        if (currentRound) {
            onEditRound(data, selectedModuleName, currentRound._id, () => {
                setCurrentRound({ ...currentRound, ...data });
            });
        }
    };

    const onFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        onEditRound({ [name]: value }, selectedModuleName, currentRound._id, () => setCurrentRound({ ...currentRound, [name]: value }));
    };

    const lastChangeDateString = currentRound?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div className="select-area">
                {client && currentRound ? (
                    <>
                        {allowedStatusesOptions && allowedStatusesOptions.length > 0 ? (
                            <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                                <Select
                                    options={allowedStatusesOptions.map((option: any, index: number) => ({
                                        label: camelCaseToText(option),
                                        value: option,
                                    }))}
                                    onChange={(item: any) => onEditRound({ status: item.value }, selectedModuleName, currentRound._id)}
                                    value={{ label: camelCaseToText(currentRound.status), value: currentRound.status }}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '100%',
                                            // minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        ) : null}

                        {repAgents && repAgents.length > 0 ? (
                            <InputWrapper status={{ text: '' }} label={'Agent / Rep'} wrapperClass="input-field select-field">
                                <Select
                                    options={repAgents.map((option: any, index: number) => ({
                                        label: option.fullName,
                                        value: option._id,
                                    }))}
                                    onChange={(item: any) => onEditRound({ [repType]: item.value }, selectedModuleName, currentRound._id)}
                                    value={{
                                        label: repAgents.find((rep: any) => rep._id === currentRound.rep)?.fullName,
                                        value: currentRound.rep,
                                    }}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '100%',
                                            // minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        ) : (
                            <div className="pension-status">No {repType} found.</div>
                        )}
                        {repOperators && repOperators.length > 0 && hasOperator(currentRound) ? (
                            <InputWrapper status={{ text: '' }} label={'Operator'} wrapperClass="input-field select-field">
                                <select
                                    value={currentRound.operator}
                                    className="form-select"
                                    name="operator"
                                    onChange={(e: any) => {
                                        callToOnEditRound({ operator: e.target.value });
                                    }}
                                >
                                    <option disabled value="">
                                        Select Operator
                                    </option>

                                    {repOperators.map((operator: User, index: number) => (
                                        <option key={index} value={operator._id}>
                                            {operator.fullName}
                                        </option>
                                    ))}
                                </select>
                            </InputWrapper>
                        ) : (
                            <div className="pension-status mb-3">No operators found.</div>
                        )}
                        <InputWrapper status={{ text: '' }} label="Potential Saving Amount" wrapperClass="input-field input-field">
                            <DebounceInput
                                value={displayNumericInputFieldsValue(currentRound.potentialSavingAmount)}
                                className="form-control"
                                name="potentialSavingAmount"
                                type="number"
                                min={0}
                                max={100}
                                debounceTimeout={1500}
                                onChange={onFieldsChange}
                                disabled={!canUserEdit}
                            />
                        </InputWrapper>
                        <InputWrapper status={{ text: '' }} label="Signing Link" wrapperClass="input-field input-field">
                            <DebounceInput
                                value={currentRound.signingLink}
                                className="form-control"
                                debounceTimeout={1500}
                                name="signingLink"
                                onChange={onFieldsChange}
                                disabled={!canUserEdit}
                            />
                        </InputWrapper>
                    </>
                ) : (
                    <div>Sorry, no data yet.</div>
                )}
            </div>
        </div>
    );
};

export default Details;
