import React from 'react';
import './InputWrapper.scss';

interface inputWrapperProps {
    children: React.ReactNode;
    label?: string | React.ReactNode;
    status?: { text: string; class?: string };
    wrapperClass?: string;
    inputName?: string;
    extraLabelText?: String;
}

const InputWrapper = ({ children, label, extraLabelText, status, wrapperClass, inputName }: inputWrapperProps) => {
    return (
        <div className={`inputWrapper ${wrapperClass ? wrapperClass : ''}`}>
            {label && (
                <>
                    <label className={`form-label`} htmlFor={inputName}>
                        {label}
                    </label>{' '}
                    <span className="extra-label-text">{extraLabelText}</span>
                </>
            )}

            {children}

            {status && <span className={`input-status ${status.class ? status.class : ''}`}>{status.text}</span>}
        </div>
    );
};

export default InputWrapper;
