import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Modules from '../../../utils/modules';
import { BankFees } from '../../../interfaces/BankFees';
import { formatDate } from '../../../utils/formatDate';
import Select from 'react-select';

const Details = () => {
    const navigate = useNavigate();

    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { currentRound, client } = profileContextData;

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('bankFees')),
    );
    console.log(
        'users',
        users.filter((rep: User) => rep.roles.includes('agent')),
    );
    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('bankFees')));
    }, [users]);

    const hasOperator = (object: any): object is BankFees => {
        return 'operator' in object;
    };
    const lastChangeDateString = currentRound?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div className="select-area">
                {client && currentRound ? (
                    <>
                        {allowedStatusesOptions && allowedStatusesOptions.length > 0 ? (
                            <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                                <Select
                                    options={allowedStatusesOptions.map((option: any, index: number) => ({
                                        label: camelCaseToText(option),
                                        value: option,
                                    }))}
                                    onChange={(item: any) => onEditRound({ status: item.value }, selectedModuleName, currentRound._id)}
                                    value={{ label: camelCaseToText(currentRound.status), value: currentRound.status }}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '100%',
                                            // minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        ) : null}

                        {repAgents && repAgents.length > 0 ? (
                            <InputWrapper status={{ text: '' }} label={'Agent / Rep'} wrapperClass="input-field select-field">
                                <Select
                                    options={repAgents.map((option: any, index: number) => ({
                                        label: option.fullName,
                                        value: option._id,
                                    }))}
                                    onChange={(item: any) => onEditRound({ [repType]: item.value }, selectedModuleName, currentRound._id)}
                                    value={{ label: repAgents.find((rep: any) => rep._id === currentRound.rep)?.fullName, value: currentRound.rep }}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '100%',
                                            // minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        ) : (
                            <div className="pension-status">No {repType} found.</div>
                        )}
                    </>
                ) : (
                    <div>Sorry, no data yet.</div>
                )}
            </div>
        </div>
    );
};

export default Details;
