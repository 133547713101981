import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { Pension } from '../../../interfaces/Pension';
import Modules from '../../../utils/modules';
import { TaxReturn } from '../../../interfaces/taxReturn';
import { FormInput } from '../../../components';
import { taxReturnFileClassifications } from './consts';
import DonwloadClientFilesButton from '../../../components/DownloadClientFilesButton';
import { formatDate } from '../../../utils/formatDate';
import AutoSavingInput from '../../../components/AutoSavingFormInput';
import Select from 'react-select';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import AutoCompleteSelect from '../../../components/auto-complete-select/AutoCompleteSelect';
import { Dropdown } from 'rsuite';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Details = () => {
    const { t } = useTranslation('taxReturn');
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [currentRound, setCurrentRound] = useState<TaxReturn>(profileContextData.currentRound as TaxReturn);

    const selectedModule = profileContextData.selectedModule;
    const taxReturnStatusGroups = selectedModule.taxReturnStatusGroups;

    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const taxFiles = [
        'donationReceipt',
        'newIdentificationCardFront',
        'newIdentificationCardFrontSpouse',
        'oldIdentificationCard',
        'oldIdentificationCardSpouse',
        'newIdentificationCardBack',
        'newIdentificationCardBackSpouse',
        'identificationCardAttachment',
        'identificationCardAttachmentSpouse',
        'form106',
        'form106Spouse',
        'academicDegreeApproval',
        'form867',
        'annualLifeInsuranceReport',
        'academicDegreeApprovalSpouse',
        'nationalInsuranceBenefit',
        'nationalInsuranceBenefitSpouse',
        'newOrReturningImmigrant',
        'newOrReturningImmigrantSpouse',
        'militaryDischarge',
        'militaryDischargeSpouse',
    ];

    const missingAnswers = [
        'האם משלם מזונות?',
        'האם את/ה הורה חד הורי? אם כן- האם מנהל משק בית משותף עם יחיד אחר?',
        'האם אחד מילדיך עבר ועדת השמה בביה""ס ו/או נמצא בחינוך מיוחד?',
        'האם סיימת תואר אקדמאי ממוסד מוכר ב-8 השנים האחרונות?',
        'האם תרמת מעל 1,000 ש""ח בשנים האחרונות?',
        'האם את/ה בעלים של דירה, ומשכיר/ה אותה בשכ""ד של מעל 5,200 ש""ח לחודש?',
        'האם סיימת שירות סדיר ב-8 השנים האחרונות?',
        'האם את/ה עולה חדש/ תושב חוזר?',
        'האם משכת כספים מקרן פנסיה/ גמל (לא פיצויים) או מקרן השתלמות לפני תום 6 שנים?',
        'האם יש לך הכנסות מחו""ל (מעבודה/ נכסים)?',
        'האם ביצעת עסקת מכירת מקרקעין כלשהי ב- 6 השנים האחרונות ושילמת מס שבח?',
        'האם את/ה בעל/ת נכות (מ- 90% ומעלה בלבד)?',
        'האם היו לך הוצאות בגין אחזקת קרוב משפחה (בן/ת זוג/ ילד/ה או הורה) במוסד בשש השנים האחרונות?',
        'האם יש לך מכתב הענקת אופציות - מכתב גרנט/ RSU?',
        'האם יש לך פעילות בני""ע/ ברוקרים זרים?',
        'האם אתה משלם בכרטיס אשראי, או הוראת קבע ישר מהחשבון, לביטוח חיים או אובדן כושר עבודה?',
    ];

    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('taxReturn')),
    );
    const [repOperators, setRepOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('taxReturn')));
        setRepOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);

    const hasOperator = (object: any): object is Pension | TaxReturn => {
        return 'operator' in object;
    };

    const callToOnEditRound = async (data: any) => {
        if (currentRound) {
            await onEditRound(data, selectedModuleName, currentRound._id, () => {
                setCurrentRound({ ...currentRound, ...data });
            });
        }
    };

    const updateAmount = async (year: number, type: string, value: number) => {
        const newAmounts = currentRound.perYearAmounts.map((item) => {
            if (item.year === year) {
                return {
                    ...item,
                    [type]: value,
                };
            }
            return item;
        });
        await callToOnEditRound({ perYearAmounts: newAmounts });
    };

    const isCalculationDoneChanged = async (year: number, isChecked: boolean) => {
        debugger;
        const newAmounts = currentRound.perYearAmounts.map((item) => {
            if (item.year === year) {
                return {
                    ...item,
                    isCalculationDone: isChecked,
                };
            }
            return item;
        });
        await callToOnEditRound({ perYearAmounts: newAmounts });
    };

    const getTotal = (type: string) => {
        let amount = currentRound.perYearAmounts.reduce((acc, item: any) => acc + item[type], 0);
        if (!amount) {
            if (type === 'estimated') {
                amount = currentRound.estimatedAmount;
            } else {
                amount = currentRound.actualAmount;
            }
        }
        return amount;
    };

    const getStatusOptions = () => {
        const options = [];
        for (const group of taxReturnStatusGroups) {
            options.push({ label: 'divider', value: t(group.name) });
            for (const status of group.statuses) {
                options.push({
                    value: status,
                    label: t(status),
                });
            }
        }
        return options;
    };

    return (
        <div
            className="detailed-tab d-flex justify-content-between rtl"
            style={{ direction: i18n.dir(), textAlign: i18n.dir() === 'rtl' ? 'right' : 'left' }}
        >
            {client && currentRound ? (
                <Row className="full-width">
                    <Col className="right-side">
                        <div className="right-side-inner">
                            {taxReturnStatusGroups && (
                                <InputWrapper
                                    status={{ text: '' }}
                                    label={t('Status')}
                                    extraLabelText={`(${t('Last change')}: ${formatDate(new Date(currentRound.lastStatusChangeDate))})`}
                                    wrapperClass="input-field select-field tax-form-item"
                                >
                                    <AutoCompleteSelect
                                        onSelect={(selectedItem) => callToOnEditRound({ status: selectedItem as string })}
                                        toggleText={
                                            <Dropdown
                                                title={
                                                    <span>
                                                        <b>{t(currentRound.status || '')}</b>
                                                    </span>
                                                }
                                            />
                                        }
                                        options={getStatusOptions()}
                                        searchKey="label"
                                        valueKey="value"
                                        placeholder={t('Search status...')}
                                        renderItem={(item) => <span>{item.label}</span>}
                                        applyExistingClasses={false}
                                        additionalClasses="autocomplete-dropdown"
                                        additionalStyles={{ width: '100%' }}
                                    />
                                </InputWrapper>
                            )}

                            <InputWrapper status={{ text: '' }} label={t('Service type')} wrapperClass="input-field select-field tax-form-item">
                                <select
                                    value={currentRound.serviceType}
                                    className="form-select"
                                    name="serviceType"
                                    onChange={(e: any) => {
                                        callToOnEditRound({ serviceType: e.target.value });
                                    }}
                                >
                                    <option value="taxRefund">{t('Tax refund')}</option>
                                    <option value="annualReport">{t('Annual report')}</option>
                                    <option value="other">{t('Other')}</option>
                                </select>
                            </InputWrapper>

                            {repAgents && repAgents.length > 0 ? (
                                <InputWrapper status={{ text: '' }} label={t('Agent / Rep')} wrapperClass="input-field select-field tax-form-item">
                                    <select
                                        value={currentRound.rep}
                                        className="form-select"
                                        name="rep"
                                        onChange={(e: any) => {
                                            callToOnEditRound({ rep: e.target.value });
                                        }}
                                    >
                                        <option disabled value="">
                                            Select {repType}{' '}
                                        </option>

                                        {repAgents.map((option: any, index: number) => (
                                            <option key={index} value={option._id}>
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                            ) : (
                                <div className="pension-status">No {repType} found.</div>
                            )}
                            {repOperators && repOperators.length > 0 && hasOperator(currentRound) ? (
                                <InputWrapper status={{ text: '' }} label={t('Operator')} wrapperClass="input-field select-field tax-form-item">
                                    <select
                                        value={currentRound.operator}
                                        className="form-select"
                                        name="operator"
                                        onChange={(e: any) => {
                                            callToOnEditRound({ operator: e.target.value });
                                        }}
                                    >
                                        <option disabled value="">
                                            Select Operator
                                        </option>

                                        {repOperators.map((operator: User, index: number) => (
                                            <option key={index} value={operator._id}>
                                                {operator.fullName}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                            ) : (
                                <div className="pension-status">No operators found.</div>
                            )}

                            <InputWrapper wrapperClass="input-field select-field tax-form-item">
                                <AutoSavingInput
                                    label={`${t('Commission Percentage')} (${t('total')}: ${Math.round((currentRound.actualAmount || currentRound.estimatedAmount || 0) * currentRound.commission)}₪)`}
                                    type="number"
                                    min={0}
                                    max={100}
                                    value={Math.round(currentRound.commission * 100)}
                                    name="commission"
                                    containerClass={'mb-3'}
                                    onSave={(value) => callToOnEditRound({ commission: Number(value) / 100 })}
                                />
                            </InputWrapper>
                            <InputWrapper wrapperClass="input-field select-field tax-form-item">
                                <AutoSavingInput
                                    label={t('IRS Branch Number')}
                                    type="number"
                                    value={currentRound.irsBranchNumber}
                                    name="irsBranchNumber"
                                    containerClass={'mb-3'}
                                    onSave={(value) => callToOnEditRound({ irsBranchNumber: Number(value) })}
                                />
                            </InputWrapper>
                            <InputWrapper wrapperClass="input-field select-field tax-form-item">
                                <AutoSavingInput
                                    label={t('IRS Branch Name')}
                                    type="text"
                                    value={currentRound.irsBranchName}
                                    name="irsBranchName"
                                    containerClass={'mb-3'}
                                    onSave={(value) => callToOnEditRound({ irsBranchName: value })}
                                />
                            </InputWrapper>
                            <InputWrapper wrapperClass="input-field select-field tax-form-item">
                                <label className="form-label">{t('Missing Documents')}</label>
                                <Select
                                    // label="IRS Branch Name"
                                    isMulti
                                    options={taxFiles.map((item) => ({
                                        value: item,
                                        label: t(item),
                                    }))}
                                    onChange={(selected: any) => {
                                        callToOnEditRound({ missingDocs: selected.map((item: any) => item.value) });
                                    }}
                                    value={currentRound.missingDocs.map((item) => ({
                                        value: item,
                                        label: t(item),
                                    }))}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '300px',
                                            minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            minWidth: '300px',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <label className="form-label">{t('Missing Answers')}</label>
                                <Select
                                    isMulti
                                    placeholder={t('Select missing answers')}
                                    options={missingAnswers.map((item) => ({
                                        value: item,
                                        label: camelCaseToText(item),
                                    }))}
                                    onChange={(selected: any) => {
                                        callToOnEditRound({ missingAnswers: selected.map((item: any) => item.value) });
                                    }}
                                    value={currentRound.missingAnswers.map((item) => ({
                                        value: item,
                                        label: camelCaseToText(item),
                                    }))}
                                    className="d-flex flex-wrap"
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            width: '300px',
                                            minWidth: '300px',
                                        }),
                                        control: (base) => ({
                                            ...base,
                                            minWidth: '300px',
                                        }),
                                    }}
                                />
                            </InputWrapper>
                        </div>
                    </Col>

                    <Col className="left-side">
                        <Row>
                            <Col className="year">
                                <div className="headline">{t('Year')}</div>
                            </Col>
                            <Col className="amount">
                                <div className="headline">{t('Calculation done')}</div>
                            </Col>
                            <Col className="amount">
                                <div className="headline">
                                    {t('Estimated amount')} / ₪{getTotal('estimated')}
                                </div>
                            </Col>
                            <Col className="amount">
                                <div className="headline">
                                    {t('Actual amount')} / ₪{getTotal('actual')}
                                </div>
                            </Col>
                        </Row>
                        {currentRound.perYearAmounts.map((yearItem) => (
                            <Row>
                                <Col className="year">{yearItem.year}</Col>
                                <Col className="year">
                                    <FormInput
                                        label=""
                                        type="checkbox"
                                        name="isCalculationDone"
                                        checked={yearItem.isCalculationDone}
                                        containerClass={'mb-3'}
                                        onChange={(e: any) => isCalculationDoneChanged(yearItem.year, e.target.checked)}
                                    />
                                </Col>
                                <Col className="amount">
                                    <AutoSavingInput
                                        type="text"
                                        value={yearItem.estimated}
                                        name="irsBranchName"
                                        containerClass={'mb-3'}
                                        onSave={(value) => updateAmount(yearItem.year, 'estimated', Number(value))}
                                    />
                                </Col>
                                <Col className="amount">
                                    <AutoSavingInput
                                        type="text"
                                        value={yearItem.actual}
                                        name="irsBranchName"
                                        containerClass={'mb-3'}
                                        onSave={(value) => updateAmount(yearItem.year, 'actual', Number(value))}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Row className="right-padding">
                            <InputWrapper>
                                <AutoSavingInput
                                    label={t('IRS Transfer Date')}
                                    type="date"
                                    value={currentRound.IRSTransferDate ? currentRound.IRSTransferDate.toString().substring(0, 10) : ''}
                                    name="IRSTransferDate"
                                    containerClass={'transfer-date'}
                                    onSave={(value) => callToOnEditRound({ IRSTransferDate: value })}
                                />
                            </InputWrapper>
                        </Row>

                        <Row className="right-padding meeting-date">
                            <label className="form-label"> {t('Agent meeting date')}:</label>
                            <span className="meeting-date-value">
                                {currentRound.agentMeetingTime ? formatDate(currentRound.agentMeetingTime) : t('Meeting not scheduled')}
                            </span>
                        </Row>
                        <Row className="right-padding download-files">
                            <DonwloadClientFilesButton
                                clientId={client._id}
                                clientName={`${client.firstName} ${client.lastName}`}
                                fileClassifications={taxReturnFileClassifications}
                                text={t('Download Files')}
                            />
                        </Row>
                    </Col>
                </Row>
            ) : (
                <div>Sorry, no data yet.</div>
            )}
        </div>
    );
};

export default Details;
