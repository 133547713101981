import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Modules from '../../../utils/modules';
import { Insurance, InsuranceClosedLostReasons } from '../../../interfaces/Insurance';
import { formatDate } from '../../../utils/formatDate';
import { DebounceInput } from 'react-debounce-input';
import { displayNumericInputFieldsValue } from '../../../utils/displayNumericValues';
import Select from 'react-select';

const closedLost = 'closedLost';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = [Modules.pension, Modules.insurance].includes(selectedModuleName) ? 'agent' : 'rep';

    const [currentRound, setCurrentRound] = useState<Insurance>(profileContextData.currentRound as Insurance);
    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('insurance')),
    );

    const [operators, setOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));

    useEffect(() => {
        setCurrentRound(profileContextData.currentRound as Insurance);
    }, [profileContextData.currentRound]);

    const handleStatusChange = (newStatus: string) => {
        if (newStatus !== closedLost) {
            onEditRound({ status: newStatus }, selectedModuleName, currentRound._id);
        }
        setCurrentRound({
            ...currentRound,
            status: newStatus,
            closedLostReason: undefined,
        });
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newReason: InsuranceClosedLostReasons = event.target.value as InsuranceClosedLostReasons;
        onEditRound({ status: closedLost, closedLostReason: newReason }, selectedModuleName, currentRound._id, () =>
            setCurrentRound({
                ...currentRound,
                status: closedLost,
                closedLostReason: newReason,
            }),
        );
    };

    const onFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        onEditRound({ [name]: value }, selectedModuleName, currentRound._id, () => setCurrentRound({ ...currentRound, [name]: value }));
    };

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('insurance')));
        setOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);

    const onAgentChanged = (agentId: string) => {
        const prevAgentId = currentRound[repType];
        setCurrentRound({ ...currentRound, [repType]: agentId });
        onEditRound({ [repType]: agentId }, selectedModuleName, currentRound._id, undefined, () =>
            setCurrentRound({ ...currentRound, [repType]: prevAgentId }),
        );
    };

    const lastChangeDateString = currentRound?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div className="select-area" style={{ maxWidth: 'none' }}>
                {client && currentRound ? (
                    <div className="row">
                        <div className="col-md-3">
                            {allowedStatusesOptions && allowedStatusesOptions.length > 0 ? (
                                <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                                    {/* <select value={currentRound.status || ''} className="form-select" name="status" onChange={handleStatusChange}>
                                        <option disabled value={''}>
                                            Select status
                                        </option>
                                        {allowedStatusesOptions.map((option: any, index: number) => (
                                            <option key={index} value={option}>
                                                {camelCaseToText(option)}
                                            </option>
                                        ))}
                                    </select> */}
                                    <Select
                                        options={allowedStatusesOptions.map((option: any, index: number) => ({
                                            label: camelCaseToText(option),
                                            value: option,
                                        }))}
                                        onChange={(item: any) => handleStatusChange(item?.value || '')}
                                        value={{ label: camelCaseToText(currentRound.status), value: currentRound.status }}
                                        className="d-flex flex-wrap"
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                width: '100%',
                                                // minWidth: '300px',
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </InputWrapper>
                            ) : null}
                            {
                                // If the close is lost, present the reason for it
                                currentRound.status === closedLost && (
                                    <InputWrapper
                                        {...(!currentRound.closedLostReason && {
                                            status: { text: 'Required field' },
                                        })}
                                        label="Close lost reason"
                                        wrapperClass="input-field select-field"
                                    >
                                        <select
                                            value={currentRound.closedLostReason || ''}
                                            className="form-select"
                                            name="reason"
                                            onChange={handleReasonChange}
                                        >
                                            <option disabled value={''}>
                                                Select reason
                                            </option>
                                            {Object.values(InsuranceClosedLostReasons).map((option: any, index: number) => (
                                                <option key={index} value={option}>
                                                    {camelCaseToText(option)}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                )
                            }
                            {repAgents && repAgents.length > 0 ? (
                                <InputWrapper status={{ text: '' }} label={'Agent'} wrapperClass="input-field select-field">
                                    <Select
                                        options={repAgents.map((option: any, index: number) => ({
                                            label: option.fullName,
                                            value: option._id,
                                        }))}
                                        onChange={(item: any) => onEditRound({ [repType]: item.value }, selectedModuleName, currentRound._id)}
                                        value={{
                                            label: repAgents.find((rep: any) => rep._id === currentRound.agent)?.fullName,
                                            value: currentRound.rep,
                                        }}
                                        className="d-flex flex-wrap"
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                width: '100%',
                                                // minWidth: '300px',
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </InputWrapper>
                            ) : (
                                <div className="pension-status">No {repType} found.</div>
                            )}

                            {operators && operators.length > 0 ? (
                                <InputWrapper status={{ text: '' }} label={'Operator'} wrapperClass="input-field select-field">
                                    <select
                                        value={currentRound.operator}
                                        className="form-select"
                                        name="operator"
                                        onChange={(e: any) => {
                                            onEditRound({ operator: e.target.value }, selectedModuleName, currentRound._id, () =>
                                                setCurrentRound({
                                                    ...currentRound,
                                                    operator: e.target.value,
                                                }),
                                            );
                                        }}
                                    >
                                        <option disabled value="">
                                            Select {'operator'}{' '}
                                        </option>
                                        {operators.map((option: any, index: number) => (
                                            <option key={index} value={option._id}>
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                            ) : (
                                <div className="pension-status">No operator found.</div>
                            )}
                        </div>
                        <div className="col-md-4" style={{ marginLeft: '30px' }}>
                            <InputWrapper status={{ text: '' }} label="First Kit Signature Date" wrapperClass="input-field input-field">
                                <input
                                    value={formatDate(currentRound.firstKitSignatureDate, true, false, 'en-CA')}
                                    className="form-control"
                                    name="firstKitSignatureDate"
                                    type="date"
                                    onChange={onFieldsChange}
                                    // onChange={e => onEditRound({ "firstKitSignatureDate": e.target.value }, selectedModuleName, currentRound._id)}
                                />
                            </InputWrapper>
                            <InputWrapper status={{ text: '' }} label="Appendices Submission Date" wrapperClass="input-field input-field">
                                <input
                                    value={formatDate(currentRound.appendicesSubmissionDate, true, false, 'en-CA')}
                                    className="form-control"
                                    name="appendicesSubmissionDate"
                                    type="date"
                                    onChange={onFieldsChange}
                                />
                            </InputWrapper>
                            {/* <InputWrapper status={{ text: '' }} label="Saving Amount" wrapperClass="input-field input-field">
                                <DebounceInput
                                    value={displayNumericInputFieldsValue(currentRound.savingAmount)}
                                    className="form-control"
                                    name="savingAmount"
                                    type="number"
                                    min={0}
                                    max={100}
                                    debounceTimeout={1500}
                                    onChange={onFieldsChange}
                                />
                            </InputWrapper> */}
                            <InputWrapper status={{ text: '' }} label="SMS2010 Link" wrapperClass="input-field input-field">
                                <DebounceInput
                                    value={currentRound.sms2010Link}
                                    className="form-control"
                                    debounceTimeout={1500}
                                    name="sms2010Link"
                                    onChange={onFieldsChange}
                                />
                            </InputWrapper>
                        </div>

                        <div>
                            <b>Agent meeting date: </b>
                            <span>{formatDate((currentRound as Insurance).agentMeetingTime)}</span>
                        </div>
                    </div>
                ) : (
                    <div>Sorry, no data yet.</div>
                )}
            </div>
        </div>
    );
};

export default Details;
