import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

interface OffCanvasWrapperProps {
    name: string;
    placement: 'start' | 'end' | 'top' | 'bottom';
    children: React.ReactNode;
    show: boolean;
    setShow: (show: boolean) => void;
}

const OffCanvasWrapper = ({ show, setShow, name, placement, children }: OffCanvasWrapperProps) => {
    const handleClose = () => setShow(false);

    return (
        <>
            <Offcanvas show={show} onHide={handleClose} placement={placement} style={{ width: '45%' }}>
                {/* <Offcanvas.Header closeButton></Offcanvas.Header> */}
                <Offcanvas.Body>{children}</Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default OffCanvasWrapper;
