import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { UserProfileContext } from '../../../context/userProfileContext';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { useQuery } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { editClient } from '../../../utils/api/clients';
import ordinal from '../../../utils/oridnalSuffix';
import { APICore } from '../../../utils/api/apiCore';
import { Tooltip } from '@mui/material';
import globalErrorHandler from '../../../utils/globalErrorHandler';
import Modules from '../../../utils/modules';

const ModuleTabs = () => {
    const query = useQuery();
    const navigate = useNavigate();

    const { profileContextData, setProfileContextData } = useContext(UserProfileContext);

    const [modules, setModules] = useState<any>(profileContextData.modules);
    const [modulesOrder, setModuleOrder] = useState<string[]>([]);
    const modulesOrderObject = profileContextData.client?.modulesOrder || [];
    const [orderChangedByDrag, setOrderChangedByDrag] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const loggedInUser = new APICore().getLoggedInUser().user;
    const isUserAllowedToDrag = loggedInUser.roles.includes('support') || loggedInUser.isAdmin;

    useEffect(() => {
        const parseModuleOrder = () => {
            // And we want to only keep a list of ordered strings
            let orderedStringArrayOfModulesNames;

            if (modulesOrderObject.length > 0) {
                orderedStringArrayOfModulesNames = modulesOrderObject.map((moduleData) => {
                    const name = moduleData.moduleName;
                    if (name === 'bankFees') return 'bankFees';
                    return name + 's';
                });
                setModuleOrder(orderedStringArrayOfModulesNames);
            } else {
                orderedStringArrayOfModulesNames = Object.keys(modules);
                setModuleOrder(orderedStringArrayOfModulesNames);
            }
        };
        parseModuleOrder();
    }, [modules]);

    const matchOrderedStringArrayToModulesOrderObject = () => {
        // For sending it to the server with editClient
        const modulesOrderObject = modulesOrder.map((moduleName: string) => ({
            moduleName: moduleName === 'bankFees' ? 'bankFees' : moduleName.slice(0, -1),
            isRelevant: modules[moduleName].isRelevant,
        }));
        return modulesOrderObject;
    };

    useEffect(() => {
        if (!orderChangedByDrag) return;
        const editClientModuleOrder = async () => {
            try {
                const modulesOrder = matchOrderedStringArrayToModulesOrderObject();
                await editClient(profileContextData?.client._id, { modulesOrder });
                setProfileContextData((prev: any) => ({
                    ...prev,
                    client: {
                        ...prev.client,
                        modulesOrder,
                    },
                }));
            } catch (err) {
                globalErrorHandler(err);
            }
        };
        editClientModuleOrder();
        setOrderChangedByDrag(false);
    }, [modulesOrder, orderChangedByDrag]);

    const onDragEnd = (result: any) => {
        if (result.destination && modulesThatAreNotDraggable.includes(modulesOrder[result.destination.index])) return;
        if (!result.destination) return;

        const sourceModuleName = modules[modulesOrder[result.source.index]].data.moduleName;
        const position = result.destination.index + 1;
        const isConfirmed = window.confirm(`Are you sure you want to make ${camelCaseToText(sourceModuleName)} the ${ordinal(position)} module?`);
        if (!isConfirmed) return;

        const newModuleOrder = Array.from(modulesOrder);
        const [removed] = newModuleOrder.splice(result.source.index, 1);
        newModuleOrder.splice(result.destination.index, 0, removed);

        setModuleOrder(newModuleOrder);
        setOrderChangedByDrag(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    const selectTab = (selected: any) => {
        navigate(`/clients/profile?clientId=${query.get('clientId')}&module=${selected?.data?.moduleName}`);
        setSelectedTab(selected?.data?.moduleName);
        setProfileContextData((prev: any) => ({
            ...prev,
            selectedModule: selected,
            currentRound: selected.data.rounds[0],
        }));
        console.log(selected);
    };

    useEffect(() => {
        setSelectedTab(profileContextData.selectedModule?.data?.moduleName);
    }, [profileContextData.selectedModule]);

    const getModuleText = (module: string) => {
        if (module === Modules.taxReturn) {
            return 'Tax';
        } else if (module === Modules.pcInsurance) {
            return 'P&C Insurance';
        }
        return camelCaseToText(module);
    };

    const hasModuleStarted = (moduleName: string) => {
        if (moduleName.includes(Modules.insurance)) {
            if (!modules[moduleName].data.rounds.length) return false;

            if (modules[moduleName].data.rounds.length === 1 && modules[moduleName].data.rounds[0].status.includes('preInitial')) return false;

            return true;
        } else {
            return !!modules[moduleName].data.rounds.length;
        }
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="modules" direction="horizontal">
                {(provided) => (
                    <div className="module-tabs-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
                        {modulesOrder.map((name, index) =>
                            modules[name] ? (
                                <Draggable
                                    key={name}
                                    draggableId={name}
                                    isDragDisabled={modulesThatAreNotDraggable.includes(name) || !isUserAllowedToDrag}
                                    index={index}
                                    disableInteractiveElementBlocking={true}
                                >
                                    {(provided) => (
                                        <Button
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            disabled={!modules[name].permittedToView}
                                            variant="primary"
                                            onClick={() => selectTab(modules[name])}
                                            className={modules[name].data.moduleName === selectedTab ? 'selected' : ''}
                                        >
                                            {getModuleText(modules[name].data.moduleName)}
                                            {modules[name].permittedToView && modules[name].data.moduleName !== Modules.onboarding && (
                                                <>
                                                    {' '}
                                                    {modules[name].isRelevant === false && (
                                                        <Tooltip title="Irrelevant module" arrow placement="bottom">
                                                            <i className="mdi mdi-flag" />
                                                        </Tooltip>
                                                    )}
                                                    {hasModuleStarted(name) && (
                                                        <Tooltip title="Module has started" arrow placement="bottom">
                                                            <i className="mdi mdi-auto-fix" />
                                                        </Tooltip>
                                                    )}
                                                    {modules[name].isPreferred && (
                                                        <Tooltip title="Preferred module" arrow placement="bottom">
                                                            <i className="mdi mdi-star" />
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </Button>
                                    )}
                                </Draggable>
                            ) : null,
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ModuleTabs;

const modulesThatAreNotDraggable = ['onboardings', 'pensions'];
