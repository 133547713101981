import { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import { Insurance, InsurancePolywizzStatuses, polywizzFields } from '../../../interfaces/Insurance';
import { formatDate } from '../../../utils/formatDate';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { Tooltip } from '@mui/material';
import { resubmitPolywizzPolicies, submitPolywizz } from '../../../utils/api/insurance';
import globalErrorHandler from '../../../utils/globalErrorHandler';
import Spinner from 'react-bootstrap/Spinner';
const InsuranceFamilyMemebrs = () => {
    const { profileContextData, onEditRound } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [currentRound, setCurrentRound] = useState<Insurance>(profileContextData.currentRound as Insurance);
    const [isPolywizzResubmitLoading, setIsPolywizzResubmitLoading] = useState<boolean>(false);
    const [isPolywizzResubmitSuccess, setIsPolywizzResubmitSuccess] = useState<boolean>(false);

    useEffect(() => {
        setCurrentRound(profileContextData.currentRound as Insurance);
    }, [profileContextData.currentRound]);

    const handleResubmitPolywizz = async (signee: 'client' | 'spouse', status: InsurancePolywizzStatuses) => {
        try {
            setIsPolywizzResubmitLoading(true);
            if (status === InsurancePolywizzStatuses.policiesError || status === InsurancePolywizzStatuses.completedPoliciesNotRequired) {
                await resubmitPolywizzPolicies(client._id, signee);
            } else {
                await submitPolywizz(client._id, signee);
            }
            setIsPolywizzResubmitSuccess(true);
        } catch (err) {
            globalErrorHandler(err);
        } finally {
            setIsPolywizzResubmitLoading(false);
        }
    };

    const getStatusContent = (data: polywizzFields, signee: 'client' | 'spouse') => {
        if (!data) return null;
        const status = data.status;
        if (!status) return null;

        if (
            [
                InsurancePolywizzStatuses.failed,
                InsurancePolywizzStatuses.timeout,
                InsurancePolywizzStatuses.missingData,
                InsurancePolywizzStatuses.policiesError,
                InsurancePolywizzStatuses.completedPoliciesNotRequired,
            ].includes(status)
        ) {
            return (
                <span className="d-inline-flex align-items-center gap-1">
                    {camelCaseToText(status)}{' '}
                    <Tooltip
                        arrow
                        placement="top"
                        title={status === InsurancePolywizzStatuses.completedPoliciesNotRequired ? 'Get policies' : 'Resubmit Polywizz'}
                    >
                        {isPolywizzResubmitLoading ? (
                            <Spinner animation="border" variant="secondary" size="sm" />
                        ) : isPolywizzResubmitSuccess ? (
                            <i className="fas fa-check-circle text-success"></i>
                        ) : (
                            <i className="mdi mdi-reload text-secondary cursor-pointer" onClick={() => handleResubmitPolywizz(signee, status)}></i>
                        )}
                    </Tooltip>
                </span>
            );
        } else {
            return camelCaseToText(status);
        }
    };

    const showKitStatus = (familyMemberType: string) => {
        if (client && currentRound && currentRound.polywizzTracker) {
            const { client: clientData, spouse: spouseData } = currentRound.polywizzTracker;

            const clientStatusContent = getStatusContent(clientData, 'client');
            const spouseStatusContent = getStatusContent(spouseData, 'spouse');

            return (
                <div className="mb-3">
                    {familyMemberType === 'client' && clientStatusContent && (
                        <div className="">
                            <span className="fw-normal fs-5">{clientStatusContent}</span>
                        </div>
                    )}
                    {familyMemberType === 'spouse' && spouseStatusContent && (
                        <div className="">
                            <span className="fw-normal fs-5">{spouseStatusContent}</span>
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div className="detailed-tab family-members">
            {currentRound.familyMembers.length ? (
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>ID Number</th>
                            <th>Date of Birth</th>
                            <th>ID Issue Date</th>
                            <th>POA Signed</th>
                            <th>Errors</th>
                            <th>Polywizz status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRound.familyMembers.map((member, index) => (
                            <tr key={index} className={member.errors.length ? 'errors' : ''}>
                                <td>{camelCaseToText(member.familyMemberType)}</td>
                                <td>{member.firstName}</td>
                                <td>{member.lastName}</td>
                                <td>{member.idNumber}</td>
                                <td>{formatDate(member.dateOfBirth, true)}</td>
                                <td>{formatDate(member.idIssueDate, true)}</td>
                                <td>{member.isPoaSigned ? 'Yes' : 'No'}</td>
                                <td>
                                    {member.errors.map((error: string) => (
                                        <div>
                                            <b>{camelCaseToText(error)}</b>
                                        </div>
                                    ))}
                                </td>
                                <td>{showKitStatus(member.familyMemberType)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="fw-normal">No family members</p>
            )}
        </div>
    );
};

export default InsuranceFamilyMemebrs;
