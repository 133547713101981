import { APICore, INSURANCE_URL } from './apiCore';

const api = new APICore();

const createInsurance = (clientId: string) => {
    return api.create(`${INSURANCE_URL}/insurance`, { clientId });
};

const editInsurance = (insuranceId: string, body: any) => {
    const relativeUrl = `/insurance/${insuranceId}`;
    return api.update(`${INSURANCE_URL}${relativeUrl}`, body);
};

const getInsuranceStatuses = () => {
    return api.get(`${INSURANCE_URL}/insurance/statuses`);
};

const submitPolywizz = (clientId: string, signee: 'client' | 'spouse') => {
    return api.create(`${INSURANCE_URL}/insurance/polywizz/submit`, { signee, clientId });
};

const resubmitPolywizzPolicies = (clientId: string, signee: 'client' | 'spouse') => {
    return api.create(`${INSURANCE_URL}/insurance//polywizz/resubmit-policies`, { signee, clientId });
};

export { getInsuranceStatuses, createInsurance, editInsurance, submitPolywizz, resubmitPolywizzPolicies };
