import { Message } from './Message';
import { User } from './User';
import { Client } from './Client';

export enum ChatStatuses {
    active = 'active',
    inactive = 'inactive',
    transferred = 'transferred',
    transferredToManager = 'transferredToManager',
}

export interface Chat {
    _id: string;
    rep: User;
    client: Client;
    unreadCount: number;
    status: ChatStatuses;
    lastMessage: Message;
    lastMessageTime: Date;
    isStarred: boolean;
    isPinned: boolean;
    pokedBy: User | string;
    follower?: string | undefined;
}

export const chatActionOptions = {
    starChat: 'Star chat',
    unStarChat: 'Unstar chat',
    pinChat: 'Pin chat',
    addFollower: 'Add follower',
    removeFollower: 'Remove follower',
    unfollow: 'Unfollow',
};

export const messageFilterTypes = {
    unread: 'unread',
    starred: 'starred',
    poked: 'poked',
    allFilters: 'allFilters',
};

export const soryByOptions = ['newest', 'oldest'];
