import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormInput } from '../../components';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { Company } from '../../interfaces/Company';
import { getNewProductData } from '../../utils/api/products';
import '../product/product.scss';
import { NewPensionProduct } from '../../interfaces/NewPensionProduct';
import { InvestmentPolicies } from '../../interfaces/Fund';
import { PensionProductTypes } from '../../interfaces/PensionProduct';
import { EmploymentStatuses } from '../../interfaces/Client';
import { banks } from '../../utils/banks';
import { structuralClone } from '../../helpers/misc';
import { UserProfileContext } from '../../context/userProfileContext';

interface CreateNewProductProps {
    show: boolean;
    isPensionRound: boolean;
    onHide?: () => void;
    onSave?: (newProduct: NewPensionProduct) => void;
}

interface NewPensionProductDataItem {
    productType: string;
    companies: Company[];
    investmentPolicies: any;
}

interface ShowField {
    productTypes: PensionProductTypes[];
    fields: string[];
}

const CreateNewPensionProduct = ({ show, onHide, onSave, isPensionRound }: CreateNewProductProps) => {
    const { profileContextData } = useContext(UserProfileContext);
    const { currentRound: pension, client } = profileContextData;

    const [newProduct, setNewProduct] = useState<NewPensionProduct>({
        clientId: client._id,
        insurance: { riskPercentage: 0, disabilityPercentage: 0 },
        employmentStatus: isPensionRound ? 'employee' : 'selfEmployed',
    });
    const [data, setData] = useState<NewPensionProductDataItem[]>();
    const [isPension, setIsPension] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [investmentPolicies, setInvestmentPolicies] = useState<string[]>([]);
    const [showFields, setShowFields] = useState<string[]>([]);

    const handleData = async () => {
        const result = await getNewProductData(isPensionRound);
        const productType = result.data[0].productType;
        setIsPension(
            productType === PensionProductTypes.comprehensivePensionFund ||
                productType === PensionProductTypes.complementaryPensionFund ||
                productType === PensionProductTypes.directorsInsurance,
        );
        setCompanies(result.data[0].companies);

        const np = { ...newProduct };
        np.productType = productType;

        const company = result.data[0].companies[0];
        np.companyId = company?._id;
        np.investmentPolicy = 'default';

        setInvestmentPolicies(result.data[0].investmentPolicies[result.data[0].companies[0]._id]);
        setNewProduct(np);
        setData(result.data);

        const item = showFieldsObj.find((item: ShowField) => (productType ? item.productTypes.includes(productType) : false));
        setShowFields(item?.fields || []);
        debugger;
    };

    useEffect(() => {
        handleData();
    }, [client]);

    const onSubmit = (e: any) => {
        if (onSave) onSave(newProduct);
    };

    useEffect(() => {}, [newProduct]);

    const showFieldsObj: ShowField[] = [
        {
            productTypes: [PensionProductTypes.complementaryPensionFund, PensionProductTypes.comprehensivePensionFund],
            fields: [
                'productType',
                'companyId',
                'relevantSalary',
                'employmentStatus',
                'employerDepositPercentage',
                'employeeDepositPercentage',
                'severancePercentage',
                'investmentPolicy',
                'commissions.deposit',
                'commissions.savings',
                'monthlyDepositAmount',
                'insurance.disabilityPercentage',
                'insurance.riskPercentage',
            ],
        },
        {
            productTypes: [PensionProductTypes.providentFund],
            fields: [
                'productType',
                'companyId',
                'oneTimeDepositAmount',
                'employmentStatus',
                'employerDepositPercentage',
                'employeeDepositPercentage',
                'severancePercentage',
                'relevantSalary',
                'monthlyDepositAmount',
                'investmentPolicy',
                'commissions.savings',
            ],
        },
        {
            productTypes: [PensionProductTypes.studyFund],
            fields: [
                'productType',
                'companyId',
                'employmentStatus',
                'employerDepositPercentage',
                'employeeDepositPercentage',
                'relevantSalary',
                'monthlyDepositAmount',
                'investmentPolicy',
                'commissions.savings',
            ],
        },
        {
            productTypes: [PensionProductTypes.investmentFund],
            fields: ['productType', 'companyId', 'oneTimeDepositAmount', 'monthlyDepositAmount', 'investmentPolicy', 'commissions.savings'],
        },
        {
            productTypes: [PensionProductTypes.savingsPolicy],
            fields: ['productType', 'companyId', 'oneTimeDepositAmount', 'monthlyDepositAmount', 'investmentPolicy', 'commissions.savings'],
        },
    ];

    const showField = (name: string) => {
        return showFields.includes(name);
    };

    const insuranceRiskOptions = () => {
        if (newProduct?.productType === 'comprehensivePensionFund') {
            return initialInsurancePercentage.risk.filter((option) => option !== 0);
        } else {
            return initialInsurancePercentage.risk;
        }
    };

    const insuranceDisabilityOptions = () => {
        if (newProduct?.productType === 'comprehensivePensionFund') {
            return initialInsurancePercentage.disability.filter((option) => option !== 0);
        } else {
            return initialInsurancePercentage.disability;
        }
    };

    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        if (!newProduct) return;

        const product = structuralClone(newProduct);

        if (name.includes('.')) {
            const list = name.split('.');
            const parent: string = list[0];
            const child: string = list[1];
            const grandChild: string = list[2];

            if (parent === 'template') {
                const index: number = Number(list[1]);

                if (!isNaN(index)) {
                    product.templates[index].isUsed = e.target.checked;
                }
            } else {
                if (!product[parent]) product[parent] = {};

                if (list.length === 2) product[parent][child] = value;
                else if (list.length === 3) {
                    if (!product[parent][child]) product[parent][child] = {};

                    product[parent][child][grandChild] = value;
                }
            }
        }

        if (name === 'productType') {
            debugger;
            if (data && newProduct.productType !== e.target.value) {
                product.productType = e.target.value;

                const item = data.find((item: NewPensionProductDataItem) => item.productType === product.productType);
                if (item) {
                    setCompanies(item.companies);
                    let company = item.companies.find((c) => c._id === product.companyId);
                    if (!company) {
                        company = item.companies[0];
                    }

                    product.companyId = company._id;

                    if (!product.investmentPolicy) {
                        product.investmentPolicy = item.investmentPolicies[company._id][0];
                    }

                    setInvestmentPolicies(item.investmentPolicies[company._id]);
                }

                if ([PensionProductTypes.investmentFund, PensionProductTypes.savingsPolicy].includes(product.productType)) {
                    product.employmentStatus = 'selfEmployed';
                }
            }
            setNewProduct(product);
            setIsPension(
                product.productType === PensionProductTypes.comprehensivePensionFund ||
                    product.productType === PensionProductTypes.complementaryPensionFund ||
                    product.productType === PensionProductTypes.directorsInsurance,
            );

            const item = showFieldsObj.find((item: ShowField) => (product.productType ? item.productTypes.includes(product.productType) : false));
            setShowFields(item?.fields || []);
        } else if (name === 'companyId') {
            if (data && product.companyId !== e.target.value) {
                product.companyId = e.target.value;
                const item = data.find((item: NewPensionProductDataItem) => item.productType === product.productType);
                if (item && product.companyId) {
                    const investmentPolicies = item.investmentPolicies[product.companyId];
                    if (!product.investmentPolicy || !investmentPolicies.includes(product.investmentPolicy))
                        product.investmentPolicy = investmentPolicies[0];

                    setInvestmentPolicies(investmentPolicies);
                }
            }
            debugger;
            setNewProduct(product);
        } else if (name === 'employmentStatus') {
            product.employmentStatus = e.target.value;
            debugger;
            setNewProduct(product);
        } else {
            product[name] = e.target.value;
            debugger;
            setNewProduct(product);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">New Product</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div>
                        {/*<FormInput*/}
                        {/*    label="Product is active"*/}
                        {/*    type="checkbox"*/}
                        {/*    name="isActive"*/}
                        {/*    placeholder="activation status"*/}
                        {/*    containerClass={'mb-3'}*/}
                        {/*/>*/}

                        {showField('productType') && (
                            <FormInput
                                label="Product type"
                                type="select"
                                name="productType"
                                placeholder="Product type"
                                containerClass={'mb-3'}
                                onChange={onChange}
                                options={data?.map((item: NewPensionProductDataItem) => ({
                                    label: camelCaseToText(item.productType),
                                    value: item.productType,
                                }))}
                            />
                        )}

                        {showField('companyId') && (
                            <FormInput
                                label="Company"
                                type="select"
                                name="companyId"
                                value={newProduct.companyId}
                                placeholder="Company"
                                containerClass={'mb-3'}
                                onChange={onChange}
                                options={companies.map((company: Company) => ({
                                    label: company.name,
                                    value: company._id,
                                }))}
                            />
                        )}

                        {showField('investmentPolicy') && (
                            <FormInput
                                label="Investment policy"
                                type="select"
                                name="investmentPolicy"
                                value={newProduct.investmentPolicy}
                                placeholder="Investment policy"
                                onChange={onChange}
                                containerClass={'mb-3 half'}
                                // onSelected={(e:any)=>onInvestmentPolicySelected(e,i)}
                                // options={investmentPolicies.map((investmentPolicy:string) => ({
                                //     label: investmentPolicy,
                                //     value: investmentPolicy
                                // }))}
                                options={Object.entries(InvestmentPolicies).map((item: string[]) => ({
                                    label: item[0],
                                    value: item[1],
                                }))}
                            />
                        )}

                        <b>100%</b>

                        {showField('insurance.disabilityPercentage') && (
                            <FormInput
                                label="Disability insurance (%)"
                                type="select"
                                containerClass={'half'}
                                name="insurance.disabilityPercentage"
                                onChange={onChange}
                                options={insuranceDisabilityOptions().map((val: number | string) => ({
                                    label: typeof val === 'string' ? val : val + '%',
                                    value: val.toString(),
                                }))}
                            />
                        )}

                        {showField('insurance.riskPercentage') && (
                            <FormInput
                                label="Risk insurance (%)"
                                type="select"
                                containerClass={'half'}
                                name="insurance.riskPercentage"
                                onChange={onChange}
                                options={insuranceRiskOptions().map((val: number | string) => ({
                                    label: typeof val === 'string' ? val : val + '%',
                                    value: val.toString(),
                                }))}
                            />
                        )}

                        {showField('commissions.savings') && (
                            <FormInput
                                label="Savings commission"
                                type="number"
                                name="commissions.savings"
                                onChange={onChange}
                                containerClass={'half'}
                                placeholder="Enter savings commission"
                                min="0"
                            />
                        )}

                        {showField('commissions.deposit') && (
                            <FormInput
                                label="Deposit commission"
                                type="number"
                                min="0"
                                name="commissions.deposit"
                                onChange={onChange}
                                containerClass={'half'}
                                placeholder="Enter deposit commission"
                            />
                        )}

                        {showField('employmentStatus') && (
                            <FormInput
                                label="Employment Status"
                                type="select"
                                containerClass={'mb-3'}
                                name="employmentStatus"
                                value={newProduct.employmentStatus || ''}
                                onChange={onChange}
                                placeholder="Employment Status"
                                options={EmploymentStatuses.map((item: string) => ({
                                    label: camelCaseToText(item),
                                    value: item,
                                }))}
                            />
                        )}

                        {newProduct.employmentStatus === 'selfEmployed' ? (
                            <>
                                <FormInput
                                    label="Monthly deposit amount"
                                    type="number"
                                    name="monthlyDepositAmount"
                                    placeholder="Enter monthly deposit amount"
                                    min="0"
                                    onChange={onChange}
                                    containerClass={'half'}
                                />

                                <FormInput
                                    label="Bank Number"
                                    type="select"
                                    options={banks}
                                    name="bankAccount.bankNumber"
                                    onChange={onChange}
                                    containerClass={'half'}
                                />

                                <FormInput label="Branch" type="number" name="bankAccount.branch" onChange={onChange} containerClass={'half'} />
                                <FormInput
                                    label="Account number"
                                    type="number"
                                    name="bankAccount.accountNumber"
                                    onChange={onChange}
                                    containerClass={'half'}
                                />

                                <FormInput
                                    label="Bank city"
                                    type="string"
                                    name="bankAccount.address.city"
                                    onChange={onChange}
                                    containerClass={'half'}
                                />
                                <FormInput
                                    label="Bank street"
                                    type="string"
                                    name="bankAccount.address.street"
                                    onChange={onChange}
                                    containerClass={'half'}
                                />
                                <FormInput
                                    label="Bank house number"
                                    type="number"
                                    name="bankAccount.address.houseNumber"
                                    onChange={onChange}
                                    containerClass={'half'}
                                />

                                {showField('oneTimeDepositAmount') && newProduct.employmentStatus === 'selfEmployed' && (
                                    <FormInput
                                        label="One time deposit amount"
                                        type="number"
                                        containerClass={'half'}
                                        name="oneTimeDepositAmount"
                                        onChange={onChange}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {showField('relevantSalary') && newProduct.employmentStatus !== 'selfEmployed' && (
                                    <FormInput
                                        label="Monthly Salary"
                                        type="number"
                                        containerClass={'half'}
                                        name="relevantSalary"
                                        onChange={onChange}
                                    />
                                )}

                                {showField('employerDepositPercentage') && newProduct.employmentStatus !== 'selfEmployed' && (
                                    <FormInput
                                        label="Employer deposit (%)"
                                        type="number"
                                        containerClass={'half'}
                                        name="employerDepositPercentage"
                                        onChange={onChange}
                                    />
                                )}

                                {showField('employeeDepositPercentage') && newProduct.employmentStatus !== 'selfEmployed' && (
                                    <FormInput
                                        label="Employee deposit (%)"
                                        type="number"
                                        name="employeeDepositPercentage"
                                        containerClass={'half'}
                                        onChange={onChange}
                                    />
                                )}

                                {showField('severancePercentage') && newProduct.employmentStatus !== 'selfEmployed' && (
                                    <FormInput
                                        label="Severance (%)"
                                        type="number"
                                        name="severancePercentage"
                                        onChange={onChange}
                                        containerClass={'half'}
                                    />
                                )}
                            </>
                        )}

                        <div className="buttons">
                            <Button variant="success" onClick={onSubmit} className="right waves-effect waves-light me-1">
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateNewPensionProduct;

const initialInsurancePercentage = {
    risk: [0, 40, 60, 100],
    disability: [0, 37.5, 75],
};
