import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { deleteFile, editFile, getEncryptedFileLink, getFileDataLabeling, getFiles } from '../../utils/api/files';
import { getFileType } from '../../utils/getFileType';
import { ClientFile } from '../../interfaces/ClientFile';
import './file-gallery.scss';
import Select from 'react-select';
import { fileDownload } from '../../utils/fileDownload';
import useInterval from '../../utils/useInterval';
import UploadLinkModal from '../profile/UploadLinkModal';
import { File, FileDataFromLabelingType } from '../../interfaces/File';
import { Client } from '../../interfaces/Client';
import { Pension } from '../../interfaces/Pension';
import { Onboarding } from '../../interfaces/Onboarding';
import { TaxReturn } from '../../interfaces/taxReturn';
import { formatDate } from '../../utils/formatDate';
import { Insurance } from '../../interfaces/Insurance';
import { BankDeposit } from '../../interfaces/BankDeposit';
import { BankFees } from '../../interfaces/BankFees';
import { Dropdown } from 'rsuite';
import { camelCaseToText } from '../../utils/camelCaseToText';
import Modules from '../../utils/modules';
import { useTranslation } from 'react-i18next';
import GalleryImageModal from './GalleryImageModal';
import { ValidFileClassificationDataForLabeling, allowedFileClassifications } from '../../interfaces/ClientFileDataLabeling';
import { GalleryImage } from '../../interfaces/GalleryImage';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorData } from '../../interfaces/ErrorData';
import { wait } from '../../utils/wait';
import globalErrorHandler from '../../utils/globalErrorHandler';

interface FileGalleryProps {
    client: Client;
    onChange: () => any;
    newfile?: ClientFile;
    currentRound?: Pension | Onboarding | TaxReturn | Insurance | BankDeposit | BankFees;
    selectedModule?: any;
}

const FileGallery = ({ client, onChange, newfile, currentRound, selectedModule }: FileGalleryProps) => {
    const { t } = useTranslation('files');
    const location = useLocation();
    const navigate = useNavigate();
    const [files, setFiles] = useState<ClientFile[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    const [fileSections, setFileSections] = useState<Option[]>([]);
    const [unclassifiedFiles, setUnclassifiedFiles] = useState<ClientFile[]>([]);
    const [classifiedFiles, setClassifiedFiles] = useState<ClientFile[]>([]);
    const [images, setImages] = useState<GalleryImage[]>([]);
    const [fileDataLabelingForForms, setFileDataLabelingForForms] = useState<ValidFileClassificationDataForLabeling[][]>([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
    const [showLargeImage, setShowLargeImage] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [showUserLink, setShowUserLink] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);
    const [classificationsFilter, setClassificationsFilter] = useState<string[]>([]);
    const [isSingleFileUpdate, setIsSingleFileUpdate] = useState(false);
    const [isImageModalLoading, setIsImageModalLoading] = useState(false);

    const [fileModalData, setFileModalData] = useState<ErrorData[]>([]);
    useEffect(() => {
        const { state } = location;

        if (state) {
            setFileModalData(state);
        }
    }, [location]);

    const isCommingFromError = fileModalData.length > 0;
    const [currentFileModalDataIndex, setCurrentFileModalDataIndex] = useState(0);
    const markedFieldsFromErrorData = isCommingFromError ? fileModalData[currentFileModalDataIndex].fields : undefined;
    const removeFieldsFromErrorData = (fields: string[], fileId: string) => {
        const index = fileModalData.findIndex((fileData: any) => fileData.fileId === fileId);
        if (index !== -1) {
            const newFileModalData = [...fileModalData];
            newFileModalData[index].fields = fileModalData[index].fields?.filter((field: string) => !fields.includes(field));
            setFileModalData(newFileModalData);
            console.log('The differnces are: ', fields);
        }
    };

    const canFileBeShownInModal = (file: ClientFile) => {
        return (
            file.thumbUrl ||
            file.largeThumbUrl ||
            file.mimeType.includes('pdf') ||
            file.mimeType.includes('jpeg') ||
            file.mimeType.includes('png') ||
            file.mimeType.includes('jpg')
        );
    };

    const findMissingFile = async (fileId: string) => {
        const results = await getFiles({ clientId: client._id });
        const file = results.files.find((f: ClientFile) => f._id === fileId);
        const fileList = [...files];
        if (file) {
            fileList.push(file);
        } else if (client.spouse?.client) {
            const results = await getFiles({
                clientId: client.spouse.client as string,
            });
            const file = results.files.find((f: ClientFile) => f._id === fileId);
            if (file) {
                fileList.push(file);
            }
        }
        setFiles(fileList);
        return fileList;
    };

    const openModalOfFileFromError = async (files: ClientFile[]) => {
        const fileIdFromErrorData = fileModalData[0].fileId;
        let index = files.findIndex((f) => f._id === fileIdFromErrorData);
        if (index === -1) {
            const files = await findMissingFile(fileIdFromErrorData);
            index = index = files.findIndex((f) => f._id === fileIdFromErrorData);
        }

        setSelectedFileIndex(index);
        setShowLargeImage(true);
        setCurrentFileModalDataIndex(0);
    };

    const openModalOfFileFromQuery = async (files: ClientFile[]) => {
        const searchParams = new URLSearchParams(location.search);
        const fileIdFromQuery = searchParams.get('fileId');
        if (fileIdFromQuery) {
            let index = files.findIndex((f) => f._id === fileIdFromQuery);
            if (index === -1) {
                const files = await findMissingFile(fileIdFromQuery);
                index = files.findIndex((f) => f._id === fileIdFromQuery);
            }

            setSelectedFileIndex(index);
            setShowLargeImage(true);
        }
    };

    const openModalOfFileHandler = (files: ClientFile[]) => {
        if (isCommingFromError) {
            openModalOfFileFromError(files);
        } else {
            openModalOfFileFromQuery(files);
        }
    };

    useEffect(() => {
        setClassificationsFilter([]);
    }, [files.length]);

    const splitFiles = (files: ClientFile[]) => {
        setUnclassifiedFiles(files.filter((f) => !f.fileClassifications.length));
        setClassifiedFiles(files.filter((f) => !!f.fileClassifications.length));
    };

    const arrangeFilesAccordingToUnclassifiedAndClassified = (files: ClientFile[]) => {
        const unclassifiedFiles = files.filter((f) => !f.fileClassifications.length);
        const classifiedFiles = files.filter((f) => !!f.fileClassifications.length);
        return [...unclassifiedFiles, ...classifiedFiles];
    };

    const onFilterSection = async (section: string) => {
        const query: {
            ids: undefined | string;
            clientId: string;
            section: string | undefined;
        } = {
            ids: undefined,
            clientId: client._id,
            section: section === 'all' ? undefined : section,
        };
        try {
            const results = await getFiles(query);
            const newFiles = arrangeFilesAccordingToUnclassifiedAndClassified(results.files);
            setFiles(newFiles);
            openModalOfFileHandler(newFiles);
            splitFiles(newFiles);
            imagesFromFiles(newFiles, client);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const onDeleteFile = async (event: React.SyntheticEvent<EventTarget>, fileId: string) => {
        event.stopPropagation();
        event.preventDefault();
        const result = window.confirm('Are you sure you want to delete this file?');
        if (!result) return;

        const index = files.findIndex((f) => f._id === fileId);

        try {
            await deleteFile(fileId);
        } catch (err) {
            alert("can't delete this file");
            return;
        }
        const newFiles = files.filter((file) => file._id !== fileId);
        const arrangedNewFiles = arrangeFilesAccordingToUnclassifiedAndClassified(newFiles);
        setFiles(arrangedNewFiles);
        openModalOfFileHandler(newFiles);
        splitFiles(newFiles);
        setSelectedFileIndex(-1);
        setCurrentFileModalDataIndex(0);
        setFileModalData([]);
        setShowLargeImage(false);

        if (client) imagesFromFiles(newFiles, client);

        onChange();
    };

    const onDownloadFile = async (event: React.SyntheticEvent<EventTarget>, fileId: string) => {
        event.stopPropagation();
        event.preventDefault();

        const index = files.findIndex((f) => f._id === fileId);
        const file = files[index];
        let url = file.link;

        await fileDownload(url, files[index].storeType === 'aws', file.fileName, file.mimeType);
    };

    const onOpenFile = async (event: React.SyntheticEvent<EventTarget>, fileId: string) => {
        event.stopPropagation();
        event.preventDefault();

        const index = files.findIndex((f) => f._id === fileId);
        const file = files[index];
        let url = file.openLink;

        window.open(url, '_blank');
    };

    const onGetEncryptedFile = async (event: React.SyntheticEvent<EventTarget>, file: File) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedFile(file);
        setShowUserLink(true);
    };

    const isFileRelevantForDataLabeling = (file: ClientFile) => {
        return file.fileClassifications.every((classification) => (allowedFileClassifications as readonly string[]).includes(classification));
    };

    const updateFileDatas = (index: number, data: ValidFileClassificationDataForLabeling[]) => {
        const newDatas = [...fileDataLabelingForForms];
        newDatas[index] = data;
        setFileDataLabelingForForms(newDatas);
    };

    const getExtractedDataFromRelevantFiles = async (fileId?: string) => {
        if (isSingleFileUpdate && !fileId) {
            setIsSingleFileUpdate(false);
            return;
        }
        setIsImageModalLoading(true);
        try {
            let idsOfRelevantFiles;
            if (fileId) {
                const file = files.find((file) => file._id === fileId && isFileRelevantForDataLabeling(file));
                if (!file) return;
                idsOfRelevantFiles = [fileId];
            } else {
                idsOfRelevantFiles = files.filter((file) => isFileRelevantForDataLabeling(file)).map((file) => file._id);
            }
            if (!idsOfRelevantFiles.length) {
                setFileDataLabelingForForms([]);
                return;
            }

            const result = await getFileDataLabeling(idsOfRelevantFiles);
            const { data } = result;

            const fileDataLabelingForForms = files.map((file) =>
                data.filter((fileData: FileDataFromLabelingType) => fileData && fileData.fileId === file._id),
            );

            setFileDataLabelingForForms(fileDataLabelingForForms);
        } catch (err) {
            globalErrorHandler(err);
        } finally {
            setIsImageModalLoading(false);
        }
    };

    useEffect(() => {
        if (showLargeImage) {
            getExtractedDataFromRelevantFiles();
        }
    }, [showLargeImage]);

    const imagesFromFiles = (files: ClientFile[], client: Client) => {
        setImages(
            files.map((file: ClientFile): GalleryImage => {
                let src = '';
                if (file.thumbUrl && file.mimeType.includes('image')) src = file.link;
                else if (file.largeThumbUrl) src = file.largeThumbUrl;
                else if (file.thumbUrl) src = file.thumbUrl;
                return {
                    clientName: `${client.firstName} ${client.lastName}`,
                    caption: `${client.firstName} ${client.lastName} ${file.fileClassifications.length ? ' | ' + file.fileClassifications[0] : ''}`,
                    src,
                    mimeType: file.mimeType,
                };
            }),
        );
    };

    useInterval(
        async () => {
            const query: { ids: undefined | string; clientId: string } = {
                ids: undefined,
                clientId: client._id,
            };

            if (currentRound) {
                if (currentRound.files.length) {
                    query.ids = currentRound.files.join(',');
                } else {
                    setFiles([]);
                    openModalOfFileHandler([]);
                    splitFiles([]);
                    imagesFromFiles([], client);
                    return;
                }
            }

            try {
                const results = await getFiles(query);
                const newFiles = arrangeFilesAccordingToUnclassifiedAndClassified(results.files);
                setFiles(newFiles);
                openModalOfFileHandler(newFiles);
                splitFiles(newFiles);
                imagesFromFiles(newFiles, client);
            } catch (err) {
                globalErrorHandler(err);
            }
        },
        9 * 60 * 1000,
    );

    const handleFiles = async () => {
        const query: { ids: undefined | string; clientId: string } = {
            ids: undefined,
            clientId: client._id,
        };

        if (currentRound) {
            if (currentRound.files.length) {
                query.ids = currentRound.files.join(',');
            } else {
                setFiles([]);
                openModalOfFileHandler([]);
                splitFiles([]);
                imagesFromFiles([], client);
                return;
            }
        }

        try {
            const results = await getFiles(query);
            const newFiles = arrangeFilesAccordingToUnclassifiedAndClassified(results.files);
            setFiles(newFiles);
            openModalOfFileHandler(newFiles);
            splitFiles(newFiles);
            imagesFromFiles(newFiles, client);
            setFileSections(results.fileSections);
            setOptions(
                results.fileClassification.map((fileClassification: string) => {
                    return {
                        value: fileClassification,
                        label: t(fileClassification),
                    };
                }),
            );
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        handleFiles();
    }, [client, currentRound]);

    useEffect(() => {
        if (newfile) handleFiles();
    }, [newfile]);

    useEffect(() => {
        const fileId = files[selectedFileIndex]?._id;
        if (!fileId) {
            const currentSearchParams = new URLSearchParams(location.search);
            currentSearchParams.delete('fileId');
            navigate(`${location.pathname}?${currentSearchParams.toString()}`);
            return;
        }
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.set('fileId', fileId);
        navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    }, [selectedFileIndex]);

    const openLightboxFromError = (fileId: string) => {
        const index = fileModalData.findIndex((fileData: any) => fileData.fileId === fileId);
        if (index !== -1) {
            setCurrentFileModalDataIndex(index);
            const fileIndex = files.findIndex((f) => f._id === fileId);
            if (fileIndex !== -1) {
                setSelectedFileIndex(fileIndex);
                setShowLargeImage(true);
            }
        }
    };

    const openLightboxFromQuery = (fileId: string) => {
        const index = files.findIndex((f) => f._id === fileId);
        if (canFileBeShownInModal(files[index])) {
            const currentSearchParams = new URLSearchParams(location.search);
            currentSearchParams.set('fileId', fileId);
            setSelectedFileIndex(index);
            setShowLargeImage(true);
            navigate(`${location.pathname}?${currentSearchParams.toString()}`);
        }
    };

    const openLightboxHandler = (fileId: string) => {
        if (isCommingFromError) {
            openLightboxFromError(fileId);
        } else {
            openLightboxFromQuery(fileId);
        }
    };

    const closeLightbox = () => {
        setShowLargeImage(false);
        setSelectedFileIndex(-1);
        setCurrentFileModalDataIndex(0);
        setFileModalData([]);
    };

    const moveNextFromError = () => {
        const nextIndex = currentFileModalDataIndex === fileModalData.length - 1 ? 0 : currentFileModalDataIndex + 1;
        setCurrentFileModalDataIndex(nextIndex);
        const nextFileId = fileModalData[nextIndex].fileId;
        const index = files.findIndex((f) => f._id === nextFileId);
        if (index !== -1) {
            setSelectedFileIndex(index);
        }
    };

    const isFileClassificationMatchTheFilter = (file: ClientFile) => {
        return file.fileClassifications.some((classification) => classificationsFilter.includes(classification));
    };

    const isFileNotClassified = (file: ClientFile) => {
        return !file.fileClassifications || file.fileClassifications.length === 0;
    };

    const checkIfFileSuitsTheFilterAndMove = (i: number) => {
        if (isFileClassificationMatchTheFilter(files[i]) || isFileNotClassified(files[i])) {
            setSelectedFileIndex(i);
            return true;
        }
        return false;
    };

    const moveNextFromQuery = () => {
        if (classificationsFilter && classificationsFilter.length > 0) {
            for (let i = selectedFileIndex + 1; i < files.length; i++) {
                if (checkIfFileSuitsTheFilterAndMove(i) && canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
            for (let i = 0; i < selectedFileIndex; i++) {
                if (checkIfFileSuitsTheFilterAndMove(i) && canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
        } else {
            for (let i = selectedFileIndex + 1; i < files.length; i++) {
                if (canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
            for (let i = 0; i < selectedFileIndex; i++) {
                if (canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
        }
    };

    const moveNextHandler = () => {
        if (isCommingFromError) {
            moveNextFromError();
        } else {
            moveNextFromQuery();
        }
    };

    const movePrevFromError = () => {
        const prevIndex = currentFileModalDataIndex === 0 ? fileModalData.length - 1 : currentFileModalDataIndex - 1;
        setCurrentFileModalDataIndex(prevIndex);
        const prevFileId = fileModalData[prevIndex].fileId;
        const index = files.findIndex((f) => f._id === prevFileId);
        if (index !== -1) {
            setSelectedFileIndex(index);
        }
    };

    const movePrevFromQuery = () => {
        if (classificationsFilter && classificationsFilter.length > 0) {
            for (let i = selectedFileIndex - 1; i >= 0; i--) {
                if (checkIfFileSuitsTheFilterAndMove(i) && canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
            for (let i = files.length - 1; i > selectedFileIndex; i--) {
                if (checkIfFileSuitsTheFilterAndMove(i) && canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
        } else {
            for (let i = selectedFileIndex - 1; i >= 0; i--) {
                if (canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
            for (let i = files.length - 1; i > selectedFileIndex; i--) {
                if (canFileBeShownInModal(files[i])) {
                    setSelectedFileIndex(i);
                    return;
                }
            }
        }
    };

    const movePrevHandler = () => {
        if (isCommingFromError) {
            movePrevFromError();
        } else {
            movePrevFromQuery();
        }
    };

    interface Option {
        label: string;
        value: string;
    }

    const fileClassificationsSelected = async (fileId: string, options: any) => {
        setIsSingleFileUpdate(true);
        const index = files.findIndex((f) => f._id === fileId);

        files[index].fileClassifications = options.map((option: { value: any }) => option.value);
        const newFiles = arrangeFilesAccordingToUnclassifiedAndClassified([...files]);
        setFiles(newFiles);
        openModalOfFileHandler(files);
        splitFiles([...files]);

        try {
            await editFile(files[index]._id, files[index].fileClassifications);
            onChange();
        } catch (err) {
            alert("Can't classify file");
        }
    };

    const getValue = (file: ClientFile) => {
        return file.fileClassifications.map((fileClassification) => ({
            value: fileClassification,
            label: t(fileClassification),
        }));
    };

    const getRow = (files: ClientFile[]) => {
        return (
            <Row className="filterable-content position-relative">
                {!files.length ? <div className="empty-table-placeholder">No files yet. Drag and drop using the box below</div> : null}
                {files.map((file, index) => {
                    return (
                        <Col sm={6} xl={4} key={index} className="filter-item  all web illustrator">
                            <div className="gal-box">
                                <div className="image-popup pointer" onClick={() => openLightboxHandler(file._id)}>
                                    <div className="inner">
                                        <div className="date">{formatDate(file.createdAt, true, false)}</div>
                                        {file.thumbUrl ? (
                                            <img src={file.thumbUrl} alt="" className="img-fluid" />
                                        ) : (
                                            <span className="avatar-title rounded file-placeholder">{getFileType(file)}</span>
                                        )}

                                        <span className="file-name">{file.fileName}</span>

                                        <div className="overlay-wrapper">
                                            <div className="overlay">
                                                <div className="action delete-file" onClick={(event) => onDeleteFile(event, file._id)}>
                                                    <i className="mdi mdi-delete-outline"></i>
                                                    <span className="text">DELETE</span>
                                                </div>
                                                <br />
                                                {file.mimeType.includes('pdf') && (
                                                    <>
                                                        <div className="action download-file" onClick={(event) => onOpenFile(event, file._id)}>
                                                            <i className="mdi mdi-open-in-new"></i>
                                                            <span className="text">OPEN</span>
                                                        </div>
                                                        <br />
                                                    </>
                                                )}
                                                <div className="action download-file" onClick={(event) => onDownloadFile(event, file._id)}>
                                                    <i className="mdi mdi-download"></i>
                                                    <span className="text">DOWNLOAD</span>
                                                </div>
                                                <br />
                                                <div className="action encrypted-file" onClick={(event) => onGetEncryptedFile(event, file)}>
                                                    <i className="mdi mdi-link-variant" />
                                                    <span className="text">USER LINK</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="gall-info">
                                    {/*<h4 className="font-16 mt-0">File type</h4>*/}
                                    {/*<span className="mb-2 font-13 file-type">*/}
                                    {/*    <strong>File type</strong>*/}
                                    {/*</span>*/}
                                    <Select
                                        isClearable={false}
                                        isRtl={true}
                                        isMulti={true}
                                        options={options}
                                        className="react-select react-select-container"
                                        value={getValue(file)}
                                        onChange={(options: any) => fileClassificationsSelected(file._id, options)}
                                    ></Select>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        );
    };

    const getFilters = () => {
        const handleFilterChange = (selectedOptions: any) => {
            if (!selectedOptions || !selectedOptions.length) {
                setClassifiedFiles(files.filter((f) => !!f.fileClassifications?.length));
                setClassificationsFilter([]);
            } else {
                const selectedValues = selectedOptions.map((option: any) => option.value);
                const filteredFiles = files.filter((file) =>
                    file.fileClassifications.some((classification) => selectedValues.includes(classification)),
                );
                setClassifiedFiles(filteredFiles.length > 0 ? filteredFiles : []);
                setClassificationsFilter(selectedValues);
            }
        };

        const selectedOptions =
            classifiedFiles.length === files.filter((f) => !!f.fileClassifications.length).length
                ? []
                : options.filter((option) => classificationsFilter.includes(option.value));

        const allExistingUniqueClassifications = files
            .reduce((acc: string[], file: ClientFile) => {
                const classifications = file.fileClassifications;
                return [...acc, ...classifications];
            }, [])
            .filter((classification, index, self) => self.indexOf(classification) === index);

        const relevantOptions = options.filter((option) => allExistingUniqueClassifications.includes(option.value));

        return (
            <div className="mb-3">
                <Select
                    isMulti
                    name="classifications"
                    options={relevantOptions}
                    styles={{ menu: (provided) => ({ ...provided, zIndex: 999 }) }}
                    onChange={handleFilterChange}
                    placeholder="Filter by Classification"
                    value={selectedOptions}
                />
            </div>
        );
    };

    const createEncryptedLink = async (fileId: string) => {
        try {
            const result = await getEncryptedFileLink(fileId);
            return { link: result.url };
        } catch (err) {
            alert("can't generated password protected file link");
        }
    };

    const isSelectedFileIndexValid = selectedFileIndex >= 0 && selectedFileIndex < files.length;
    console.log('selectedFileIndex: ', selectedFileIndex, ' files.length: ', files.length, ' files: ', files);

    return (
        <>
            {selectedFile ? (
                <UploadLinkModal
                    show={showUserLink}
                    onHide={() => setShowUserLink(false)}
                    title="Password Protected File"
                    getLink={() => createEncryptedLink(selectedFile._id)}
                />
            ) : null}

            {selectedModule?.data.moduleName === Modules.insurance ? (
                <Dropdown
                    title={`Section: ${selectedSection || 'all'}`}
                    onSelect={(value: any) => {
                        setSelectedSection(value);
                        onFilterSection(value);
                    }}
                >
                    {fileSections.map((section) => (
                        <Dropdown.Item eventKey={section}>{camelCaseToText(section as any)}</Dropdown.Item>
                    ))}
                </Dropdown>
            ) : null}

            {!unclassifiedFiles.length && !classifiedFiles.length ? (
                <Card>
                    <div className="card-body">
                        <h4 className="header-title mb-3">No files yet. Drag and drop using the box top upload</h4>
                    </div>
                </Card>
            ) : null}

            {unclassifiedFiles.length ? (
                <Card className="file-gallery">
                    <Card.Body>
                        <h4 className="header-title mb-3">Unclassified Files</h4>

                        {getRow(unclassifiedFiles)}
                    </Card.Body>
                </Card>
            ) : null}
            {classifiedFiles.length ? (
                <Card className="file-gallery">
                    <Card.Body>
                        <div className="d-flex justify-content-between">
                            <h4 className="header-title mb-3">Classified Files</h4>
                            {getFilters()}
                        </div>
                        {getRow(classifiedFiles)}
                    </Card.Body>
                </Card>
            ) : null}
            {showLargeImage && isSelectedFileIndexValid && files.length > 0 && images.length > 0 && (
                <GalleryImageModal
                    fileImage={images[selectedFileIndex]}
                    fileIndex={selectedFileIndex}
                    fileId={files[selectedFileIndex]._id}
                    fileType={files[selectedFileIndex].mimeType}
                    fileLink={files[selectedFileIndex].openLink || files[selectedFileIndex].link}
                    fileClassifications={files[selectedFileIndex].fileClassifications}
                    closeLightbox={closeLightbox}
                    moveNext={moveNextHandler}
                    movePrev={movePrevHandler}
                    fileData={fileDataLabelingForForms[selectedFileIndex]}
                    updateFileDatas={updateFileDatas}
                    isFileRelevantForDataLabeling={isFileRelevantForDataLabeling(files[selectedFileIndex])}
                    optionsForMultiClassificationSelect={options}
                    getClassificationInHebrew={(value) => t(value)}
                    setClassificationsForFile={(options: any) => fileClassificationsSelected(files[selectedFileIndex]._id, options)}
                    isPageLoading={isImageModalLoading}
                    {...(markedFieldsFromErrorData && {
                        markedFields: markedFieldsFromErrorData,
                    })}
                    onRemoveFieldsFromErrorData={(fields: string[]) => removeFieldsFromErrorData(fields, files[selectedFileIndex]._id)}
                    onDeleteFile={(event: React.SyntheticEvent<EventTarget>) => onDeleteFile(event, files[selectedFileIndex]._id)}
                />
            )}
        </>
    );
};

export default FileGallery;
